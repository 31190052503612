.main_searchStudent
    width: 100%
    display: inline-block
    .headSearchStudent
        padding: 20px 20px 20px 0px
        text-align: right
        i
            text-align: right
            font-size: 30px
            color: #fff
            transition: 0.3s
            &:hover
                color: var(--color-primary)
    .bodySearchStudent
        overflow-y: auto
        height: calc(100vh - 70px)
        background-color: #fff
        display: flex
        flex-direction: column
        align-items: center
        .contentSearch
            width: 80%
            text-align: left
            span
                color: var(--color-secondary)
            .back_button
                margin-top: 20px
                width: fit-content
                padding: 5px
                cursor: pointer
                i
                    margin-right: 10px
            .search_container
                position: relative
                width: 50%
                input
                    background: #F8F9FA
                    border-radius: 16px
                    border: none
                    padding: 10px
                    width: 100%
                i
                    position: absolute
                    top: 8px
                    right: 10px
                    color: #CDD4DB
        .containerBody
            width: 80%
            height: 100%
            .content_certificate_body
                width: 50%
                background: #F8F9FA
                text-align: left
            .contentBody
                width: 50%
                margin-top: 30px
                background: #F8F9FA
                padding: 20px
                height: 50%
                display: flex
                justify-content: center
                align-items: center
                .response_body
                    h5
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        line-height: 14px
                        letter-spacing: 0.4px
                        color: #7A8D9F
                    button
                        background: var(--color-secondary)
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14)
                        border-radius: 12px
                        border: none
                        padding: 10px
                        text-align: center
                        letter-spacing: 1.25px
                        text-transform: uppercase
                        color: #F5F6F8
                        transition: 0.3s
                        opacity: 1
                        &:hover
                            opacity: 0.8
                        i
                            color: #fff
            .content_add_user
                width: 50%
                text-align: center
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .text_container
                    background: #F8F9FA
                    border-radius: 12px
                    padding: 5px
                    p
                        margin: 0
                        text-align: justify
            .bodyAddUser
                background: #fff
                border-bottom-left-radius: 15px
                border-bottom-right-radius: 15px
                padding: 22px 0
                display: grid
                grid-row-gap: 15px
                width: 80%
                .contPhotoUser
                    display: flex
                    justify-content: center
                    .contImgUser
                        display: flex
                        width: max-content
                        gap: 18px
                        align-items: center
                        .imgDeforView
                            width: 63px
                            height: 63px
                            border-radius: 50%
                            background: var(--color-secondary)
                            display: flex
                            align-items: center
                            justify-content: center
                            i
                                color: #fff
                                font-size: 22px
                        label
                            cursor: pointer
                            color: var(--color-primary)
                            font-size: 14px
                            input
                                display: none
                .contFormCour
                    display: grid
                    grid-auto-flow: row
                    grid-row-gap: 15px
                    text-align: left
                    font-size: 14px
                    color: #908C8C
                    &.scrollBody
                        height: auto
                        max-height: 420px
                        overflow-y: auto
                        padding: 0 28px
                    .sepContAddUser
                        display: grid
                        grid-template-columns: repeat(2, 1fr)
                        grid-gap: 16px
                    .contInAddUser
                        width: 100%
                        p
                            margin: 0 0 5px 0
                            color: #000
                        input, select
                            border: 1px solid #D8CCCC
                            box-sizing: border-box
                            border-radius: 10px
                            outline: navajowhite
                            padding: 0.5rem
                            width: 100%
                        select
                            cursor: pointer
                    .optsBtnAdd
                        display: grid
                        padding: 0 28px
                        .containerSpinner
                            background: var(--color-primary)
                            border: none
                            border-radius: 10px
                            width: 100%
                            // padding: 0.5rem
                            color: #ffffff
                            font-weight: 500
                            font-size: 14px
                            text-align: center
                            justify-content: center
                            display: flex
                            align-items: center
                        button
                            padding: 0.5rem
                            border-radius: 10px
                            border: 1px solid #D8CCCC
                            color: #908C8C

                            cursor: pointer
                            &:last-child
                                border: none
                                color: #fff
                                background: var(--color-secondary)
            .info_user
                margin-top: 20px
                padding: 10px
                display: flex
                background: #F8F9FA
                width: 50%
                justify-content: space-between
                .img_photo
                    width: 40px
                    height: 40px
                    margin-right: 20px
                    border-radius: 50%
                .data_info
                    width: 100%
                    display: flex
                    justify-content: space-between
                    .data_info_name
                        p
                            text-align: left
                            margin: 0
                            &:first-child
                                text-transform: capitalize
                    .assing_name
                        display: flex
                        justify-content: center
                        align-items: center
                        cursor: pointer
                        .button_center
                            border: 1px solid var(--color-secondary)
                            box-sizing: border-box
                            border-radius: 12px
                            color: var(--color-secondary)
                            padding: 2px 8px
                            transition: 0.3s
                            &:hover
                                color: #fff
                                border: 1px solid var(--color-secondary)
                                background-color: var(--color-secondary)
            .buttons_container
                display: flex
                button
                    border: 1px solid var(--color-secondary)
                    box-sizing: border-box
                    border-radius: 12px
                    font-family: Roboto
                    font-style: normal
                    font-weight: 500
                    font-size: 10px
                    line-height: 12px
                    text-align: center
                    letter-spacing: 1.25px
                    text-transform: uppercase
                    color: var(--color-secondary)
                    padding: 8px
                    cursor: pointer
                    background-color: #fff
                    &:first-child
                        margin-right: 20px
