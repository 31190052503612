.container_RepositorioPage
    display: grid
    grid-template-rows: 50px auto
    grid-row-gap: 20px
    width: 100%
    overflow-y: auto
    .headUserPag
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
        div
            width: 120px
            display: flex
            align-items: center
            justify-content: space-between
    .bodyUserPag
        overflow-y: auto
        overflow-x: hidden
        display: grid
        grid-auto-flow: row
        grid-row-gap: 10px
        padding-right: 30px
        .cardEntity_container
            border-radius: 24px
            background-color: #fff
            padding: 20px
            .cardEntity_content
                background-color: #fff
                padding: 20px
            .cardEntity_body
                // display: flex
                justify-content: space-between
                background-color: #fff
                padding: 20px
    .bodyUserPagCredential
        display: block
        padding-right: 30px
        .options_credentials
            display: flex
            margin-bottom: 20px
            .item_credentials
                padding: 8px
                height: fit-content
                border-radius: 12px
                font-style: normal
                font-weight: bold
                font-size: 14px
                line-height: 17px
                letter-spacing: 0.15px
                color: #CDD4DB
                cursor: pointer
                &.active
                    background: var(--color-secondary)
                    color: #fff
                &:last-child
                    margin-left: 10px
                p
                    margin: 0
        .cardEntity_container
            height: 100%
            .cardEntity_body
                background-color: #fff
                padding: 20px
                height: 100%
