#root-portal-all-page
    background: rgba(0, 0, 0, 0.3)
    position: fixed
    height: 100vh
    width: 100vw
    top: 0
    display: table
    z-index: 10
    animation: showModal 0.4s
.contentModal-all-page
    display: flex
    text-align: center
    vertical-align: middle

@keyframes showModal
    0%
        opacity: 0
    100%
        opacity: 1
