.card_entity_container
    display: flex
    align-items: center
    justify-content: center
    .card_entity_content
        position: relative
        box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04)
        border-radius: 20px
        width: 220px
        height: 280px
        // cursor: pointer
        .img_background
            height: 100%
            width: 100%
            border-radius: 20px
            background-size: cover
            background-repeat: no-repeat
            background-position: 50%
        .overlay_cardImage
            background: #000000
            opacity: 0.2
            width: 100%
            height: 100%
            position: absolute
            top: 0
            border-radius: 20px
        .infoCardEntity
            z-index: 1
            position: absolute
            background: rgba(0, 0, 0, 0.1)
            backdrop-filter: blur(48px)
            border-radius: 0px 0px 20px 20px
            padding: 7px
            bottom: 0
            width: 100%
            text-align: start
            height: 98px
            .header_infoCardEntity
                display: flex
                justify-content: space-between
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 16px
                color: #F6F6F6
                text-transform: capitalize
                .right_content_infoCardEntity
                    display: flex
                    align-items: center
                    z-index: 1
                    padding: 3px
                    cursor: pointer
                .left_content_infoCardEntity
                    display: flex
                    align-items: center
                    width: 90%
                    .containerImageEntity
                        background-color: #ffffff
                        border-radius: 50%
                        width: 32px
                        height: 32px
                        display: flex
                        justify-content: center
                        align-items: center
                        img
                            border-radius: 50%
                            height: auto
                            width: 32px
                            height: 32px
                    span
                        margin-left: 10px
                        width: 100%
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                .contOptSelOpt
                    position: absolute
                    right: 0
                    top: 0
                    padding-top: 33px
                .optSelectOption
                    // position: absolute
                    // right: 0px
                    color: #000
                    // top: 33px
                    padding: 1px 0 1px 0
                    width: 170px
                    font-size: 14px
                    z-index: 1
                    align-items: flex-start
                    background: #FFFFFF
                    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
                    border-radius: 5px
                    .onlyOpt

                        color: #000000
                        cursor: pointer
                        // width: 150px
                        width: 100%
                        padding: 7px
                        display: grid
                        grid-template-columns: auto
                        align-items: center
                        grid-column-gap: 10px
                        i
                            font-size: 24px
                        &:first-child
                            margin-top: 8px
                        &:last-child
                            margin-bottom: 8px
                        &:hover
                            background: #B3B3B3
            .description_infoCardEntity
                font-style: normal
                font-weight: normal
                font-size: 10px
                line-height: 12px
                color: #F6F6F6
                padding-left: 44px
                padding-right: 10px
                p
                    margin: 0
                    overflow: hidden
                    text-overflow: ellipsis
                    display: -webkit-box
                    -webkit-line-clamp: 3
                    -webkit-box-orient: vertical
                    word-wrap: break-word
