$color-personal: #bb005c;
$second-color: #ffcde3;

.chat-user {
    display: flex;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    &__container {
        display: flex;
        flex-direction: column;
        border-radius: 1.5rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
    &__header {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem;
        background-color: var(--color-primary);
        color: #fff;
        font-weight: bold;
        border-radius: 1.5rem 1.5rem 0rem 0rem;
    }
    &__body {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
        padding: 0.5rem 4rem;
        &-message {
            @extend %message;
            justify-content: start;
            p {
                @extend %message-text;
                background-color: #f5f5f5;
                margin-right: 12rem !important;
                @media screen and (max-width: 768px) {
                    margin-right: 8rem !important;
                }
                // border: 2px solid $second-color;
            }
            &-personal {
                @extend %message;
                justify-content: end;
                p {
                    @extend %message-text;
                    background-color: $second-color;
                    color: $color-personal;
                    margin-left: 12rem !important;
                    @media screen and (max-width: 768px) {
                        margin-left: 8rem !important;
                    }
                    // border: 2px solid var(--color-primary);
                }
            }
        }
    }
    &__footer {
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
    }
    &__input {
        display: flex;
        border: 2px solid #cdd4db;
        border-radius: 5px;
        padding: 0.5rem;
        width: 100%;
        font-size: 1rem;
        &:focus {
            border: 2px solid var(--color-primary);
        }
    }
    &__button {
        background-color: var(--color-primary);
        border: none;
        border-radius: 5px;
        padding: 0.5rem;
        color: white;
        font-size: 1rem;
        width: 20%;
        // height: 38px;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
    &__modal {
        // background-color: red;
        position: absolute;
        bottom: 75px;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        &-button {
            padding: 0.7rem;
            width: 170px;
            // width: auto;
            background-color: $second-color;
            border-radius: 0.5rem;
            border: none;
            // color: #cc337e;
            color: var(--color-primary);
            &:hover {
                // opacity: 0.8;
                cursor: pointer;
                color: $second-color;
                // background-color: #cc337e;
                background-color: var(--color-primary);
            }
        }
    }
}

%message {
    display: flex;
    // align-items: center;
    // padding: 0.5rem;
    // border-radius: 0.5rem;
    // margin-bottom: 0.5rem;
    // width: 100%;
}

%message-text {
    padding: 0.8rem 1.5rem;
    // margin: 0rem;
    margin: 0rem;
    border-radius: 0.5rem;
    font-weight: 500;
    // width: 100%;
}
