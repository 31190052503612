.main_AccessPage
    width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    padding: 1rem
    background-color: #fff
    form
        display: grid
        grid-template-rows: auto max-content
        grid-row-gap: 18px
        .formBody
            display: grid
            grid-auto-flow: row
            grid-auto-rows: max-content
            grid-row-gap: 18px
            letter-spacing: 1.5px
            font-weight: 700
            font-size: 11px
            &.moreData
                max-height: 200px
                padding-right: 10px

            span
                text-align: right
                color: #197BFF
                cursor: pointer
            label
                position: relative
                input
                    padding: 0.8rem
                    border-radius: 10px
                    border: none
                    letter-spacing: 1.25px
                    background: rgba(240, 240, 240, 0.4)
                    outline: none
                    width: 100%
                    box-sizing: border-box
                    &::-ms-reveal
                        display: none
                .inpPass
                    padding-right: 3rem
                    &.incorrect
                        border: 2px solid #ff0000
                i
                    color: #888787
                    position: absolute
                    font-size: 18px
                    right: 10px
                    bottom: 14px
                    cursor: pointer
                .msgDetail
                    color: red
            .info
                color: #7A8D9F
                font-family: 'Roboto'
                font-size: 12px
        .formFooter
            display: grid
            grid-row-gap: 16px
            button
                padding: 1rem
                border-radius: 45px
                border: none
                width: 100%
                background-color: #197BFF
                color: #fff
                letter-spacing: 1.25px
                font-family: system-ui
                font-weight: 700
                outline: none
                cursor: pointer
            .contChangeOpt
                display: flex
                justify-content: center
                grid-column-gap: 10px
                font-size: 13px
                font-family: 'Roboto'
                letter-spacing: 0.25px
                font-weight: 700
                span:nth-child(2)
                    font-family: system-ui
                    font-size: 11px
                    letter-spacing: 1.5px
