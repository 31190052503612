.progressNS
    &--box
        padding: 10px
        display: flex
        align-items: center
        justify-content: center
        height: 100%  
        width: 100%  
        background: #fff    
    &--wrap
        width: 100px
        height: 100px
        position: relative
        animation: progressNS 2.5s infinite linear both

        &-dot
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            animation: progressNS-dot 2.0s infinite ease-in-out both

        &-dot:before
            content: ''
            display: block
            width: 25%
            height: 25%
            // background-color: #1fc6be
            background: var(--color-secondary)
            border-radius: 100%
            animation: progressNS-dot-before 2.0s infinite ease-in-out both

        &-dot:nth-child(1)
            animation-delay: -1.1s

        &-dot:nth-child(2)
            animation-delay: -1.0s

        &-dot:nth-child(3)
            animation-delay: -0.9s

        &-dot:nth-child(4)
            animation-delay: -0.8s

        &-dot:nth-child(5)
            animation-delay: -0.7s

        &-dot:nth-child(6)
            animation-delay: -0.6s

        &-dot:nth-child(1):before
            animation-delay: -1.1s

        &-dot:nth-child(2):before
            animation-delay: -1.0s

        &-dot:nth-child(3):before
            animation-delay: -0.9s

        &-dot:nth-child(4):before
            animation-delay: -0.8s

        &-dot:nth-child(5):before
            animation-delay: -0.7s

        &-dot:nth-child(6):before
            animation-delay: -0.6s

@keyframes progressNS
    100%
        transform: rotate(360deg)

@keyframes progressNS-dot
    80%, 100%
        transform: rotate(360deg)

@keyframes progressNS-dot-before
    50%
        transform: scale(0.4)
    100%, 0%
        transform: scale(1.0)