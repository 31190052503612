.container_ItemUser
    overflow-y: hidden
    height: 48px
    transition: height 0.25s ease 0s
    .heatItenUsr
        display: grid
        grid-template-columns: 205px auto
        grid-column-gap: 10px
        box-shadow: 0px 1px 8px 0px rgba(107, 109, 126, 0.5)
        padding: 10px
        border-radius: 8px
        font-size: 15px
        margin: 10px 2px 3px
        position: relative
        &:first-child
            margin-top: 4px
        .optInfoUser
            display: grid
            grid-template-columns: max-content max-content auto
            grid-column-gap: 10px
            // div:first-child
            &.list
                grid-template-columns: 60px auto
                place-items: center
            i
                font-size: 20px
                cursor: pointer
                transition: transform 0.3s
                &.active
                    transform: rotate(-180deg)
            .franjList
                position: absolute
                left: 0
                width: 13px
                top: 0
                bottom: 0
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
            .contImgUser
                display: flex
                justify-self: right
                img
                    width: 36px
                    height: 36px
                    border-radius: 50%
        .nElement
            text-align: right
        .optOptUser
            display: grid
            grid-template-columns: 150px 70px 85px 85px minmax(140px, auto) minmax(140px, auto)
            grid-column-gap: 10px
            overflow-x: auto
            cursor: pointer
            color: #908C8C
            text-align: center
            align-items: center
            &.other
                grid-template-columns: 150px 70px 85px 85px auto
            &::-webkit-scrollbar
                display: none
            .buttonOptUser
                width: 100%
                height: 80%
                background: #FFFFFF
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
                border-radius: 5px
                display: flex
                justify-content: center
                align-items: center
            .optMoreData
                display: grid
                grid-template-columns: repeat(2, minmax(140px, 1fr))
                grid-column-gap: 10px
                div
                    white-space: nowrap
                    text-overflow: ellipsis
                    overflow: hidden

.selectOptionUser
    position: relative
    width: 100%
    height: 29px
    background: #FFFFFF
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)
    border-radius: 45px
    display: flex
    align-items: center
    justify-content: space-around
    i
        font-size: 10px
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        right: 10px
        background-color: #197BFF
        border-radius: 50%
        color: #ffffff
        padding: 5px
