.bodyUserPag
    &.Modules
        .content_data_course
            .left_data
                .img_course
                    width: 100px
                    height: 100px
                    // margin: 10px
                .name_Category
                    .category
                        font-size: 12px
                        text-transform: uppercase
                        color: #908C8C
                    .name
                        font-weight: bold
                        font-size: 16px
                        color: #19191D
                    .description
                        font-size: 13px
                        color: rgba(0, 0, 0, 0.45)
                        text-overflow: ellipsis
                        overflow: hidden
                        display: -webkit-box
                        -webkit-line-clamp: 3
                        -webkit-box-orient: vertical
                        word-break: break-all
            .right_data
                width: 230px
                .date_title
                    margin: 0
                .date
                    margin-bottom: 10px
        .content_modules
            display: flex
            justify-content: center
            align-items: center
            .list_modules
                margin-right: 20px
                // width: 245px
                border-radius: 10px
                background: #fff
                padding: 10px
                height: max-content
                .listEvaluations
                    margin-left: 0
                    padding: 0
                    .itemaForum
                        grid-template-columns: 35px calc( 100% - 55px) 20px
                        .icon
                            width: 30px
                            height: 30px
                            background: #fff
                            display: flex
                            align-items: center
                            padding: 7px
                            border-radius: 5px
                            // i

                .title
                    font-weight: bold
                    font-size: 12px
                    text-align: center
                    text-transform: uppercase
                    color: #19191D
                .list
                    list-style: none
                    margin: 15px 0
                    padding: 0
                    .item_module
                        display: flex
                        align-items: center
                        justify-content: space-evenly
                        margin: 10px 0
                        cursor: pointer
                        .number
                            background: gray
                            border: 2px solid rgba(255,255,255,0.7)
                            box-sizing: border-box
                            border-radius: 50%
                            width: 35px
                            height: 35px
                            display: flex
                            align-items: center
                            justify-content: center
                            color: #fff
                            &.active
                                background-color: var(--color-secondary)
                            span
                                font-size: 8px
                                margin-top: 5px
                        i
                            color: var(--color-secondary)
                            font-size: 25px
            .content_data_module
                flex: auto
                min-height: 500px
                border-radius: 10px
                background: #fff
                padding: 10px
                width: calc(100% - 298px)
                height: 100%
                .info_module
                    margin: 20px
                    padding: 10px
                    height: max-content
                    background: #F8F9FA
                    border: 1px solid #CDD4DB
                    box-sizing: border-box
                    border-radius: 10px
                    display: flex
                    .left_info_module
                        flex: auto
                        .info_1
                            font-weight: 500
                            color: #6E6D7A
                            font-size: 14px
                            span
                                margin-left: 10px
                                color: var(--color-secondary)
                        .info_2
                            margin: 5px 0
                            display: flex
                            .info_1
                                margin-right: 40px
                    .right_info_module
                        display: flex
                        align-items: center
                        flex-wrap: wrap
                        width: 150px
                        width: 150px
                        text-align: center
                        justify-content: center
                        .btn_program
                            color: #fff
                            background: var(--color-secondary)
                            border-radius: 5px
                            width: 105px
                            height: 32px
                            border: none
                            cursor: pointer
                            outline: none
                        span
                            width: 100%
                            font-weight: 500
                            color: #ff0000
                            font-size: 13px
                // .materials_module
.content_data_course
    height: auto
    background: #FFFFFF
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    // margin: 10px 0
    display: flex
    flex-direction: column
    row-gap: 10px
    // align-items: center
    justify-content: space-between
    // padding: 0 30px
    color: #908C8C
    .left_data
        display: flex
        align-items: center
        column-gap: 20px
        .img_course
            width: 50px
            height: 50px
            border-radius: 50%
            // margin-right: 40px
        .name_Category
            margin-right: 40px
            .name
                font-size: 20px
                margin-bottom: 2px
            .separador
                width: 97px
                height: 2px
                background: var(--color-primary)
                border-radius: 11px
            .category
                font-size: 18px
        .student_teacher
            .students
                font-size: 14px
                margin-bottom: 10px
                span
                    font-weight: 500
            .teacher
                font-size: 14px
                span
                    font-weight: 500
    .right_data
        .date_title
            font-size: 14px
            font-weight: 500
            margin-bottom: 10px
        .date
            font-size: 14px
    .desmodData
        border: 1px solid #cccccc
        border-radius: 10px
        font-size: 13px
        .item
            display: flex
            column-gap: 10px
            padding: 5px 10px
            border-bottom: 1px solid #cccccc
            &:last-child
                border-bottom: none
@media screen and ( max-width: 700px )
    .bodyUserPag
        &.Modules
            .content_data_course
                padding: 10px 20px
                flex-wrap: wrap
                .right_data
                    display: flex
                    margin-left: 80px
                    .date_content
                        width: 50%
                    div
                        font-size: 11px
                .left_data
                    .img_course
                        width: 70px
                        height: 70px
                        margin: 10px 10px 0 0
                    .name_Category
                        margin-right: 10px
                        .name
                            font-size: 14px
                        .description
                            font-size: 11px
                            overflow-x: auto
                            margin-bottom: 10px
