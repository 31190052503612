.container_studentInscribedPage
    display: grid
    grid-template-rows: 50px calc(100% - 50px)
    width: 100%
    overflow-y: auto
    // height: calc( 100% - 36px )
    .headStudentInscribedPag
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
    .bodyStudentInscribedPag
        overflow-y: auto
        overflow-x: hidden
        display: grid
        grid-auto-flow: row
        grid-row-gap: 10px
        padding-right: 30px
        margin-top: 20px
        .content_inscribedStudents
            display: grid
            grid-template-columns: 300px auto
            width: 100%
            grid-column-gap: 10px
            overflow-y: auto
            .courses_content_sidebar
                background-color: #ffffff
                padding: 20px
                overflow-y: auto
                .item_course
                    cursor: pointer
                    padding: 10px
                    display: grid
                    // grid-template-columns: 175px 15px
                    grid-template-columns: 45px 150px 15px
                    justify-content: space-between
                    align-items: center
                    border-radius: 12px
                    background-color: #fff
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04)
                    opacity: 0.8
                    margin-bottom: 20px
                    &.active
                        background-color: #F8E5F0
                    .image_item
                        width: 100%
                        height: 100%
                        .content_image_item
                            width: 100%
                            height: 100%
                            display: flex
                            border-radius: 6px
                            justify-content: center
                            align-items: center
                            background-color: #fff
                            color: var(--color-secondary)
                    .info_item
                        width: 100%
                        // padding-left: 10px
                        .title_name
                            width: 100%
                            margin: 0
                            text-transform: capitalize
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                            &:first-child
                                font-weight: bold
                            &:last-child
                                color: #757f88
                    .arrow_item
                        width: 100%
                        display: flex
                        align-items: center
                        justify-content: flex-end
                // .item_course
                //     cursor: pointer
                //     padding: 10px
                //     display: grid
                //     grid-template-columns: 40px 1fr 15px
                //     justify-content: space-between
                //     align-items: center
                //     border-radius: 12px
                //     background-color: var(--color-primary)
                //     opacity: 0.8
                //     margin-bottom: 20px
                //     .image_item
                //         width: 100%
                //         height: 100%
                //         .content_image_item
                //             width: 100%
                //             height: 100%
                //             display: flex
                //             border-radius: 6px
                //             justify-content: center
                //             align-items: center
                //             background-color: #fff
                //             color: var(--color-primary)
                //     .info_item
                //         width: 100%
                //         padding-left: 10px
                //         p
                //             width: 100%
                //             margin: 0
                //             text-transform: capitalize
                //             white-space: nowrap
                //             overflow: hidden
                //             text-overflow: ellipsis
                //             &:first-child
                //                 color: #7A8D9F

                //     .arrow_item
                //         width: 100%
                //         display: flex
                //         align-items: center
                //         justify-content: flex-end
            .list_courses_students
                // padding: 20px
                // display: grid
                // grid-template-rows: max-content auto
                // grid-row-gap: 15px
                overflow-y: auto
                h3
                    text-transform: capitalize
                    color: var(--color-primary)
                    padding: 15px
                    display: flex
                    align-items: center
                    padding-left: 15px
                    background-color: #ffffff
                    margin: 0
                .detail_student_container
                    background-color: #fff
                    padding: 20px
                    height: 100%
                    .back_button
                        h4
                            span
                                text-transform: capitalize
                                color: var(--color-secondary)
                    .info_student_content
                        display: flex
                        background: #F8F9FA
                        border-radius: 12px
                        margin-bottom: 20px
                        padding: 10px
                        .image_student
                            display: flex
                            align-items: center
                            margin-right: 20px
                            img
                                width: 90px
                                height: 90px
                                border-radius: 10px
                                background-color: #fff
                        .imgDeforView
                            i
                                font-size: 90px
                        .data_patagraph_content
                            p
                                margin: 0
                                &:first-child
                                    text-transform: capitalize
                                &:last-child
                                    text-transform: capitalize
                    .detail_part_student
                        .option_select
                            display: flex
                            flex-direction: column
                            .list_opt_slc
                                display: flex
                                .item
                                    text-transform: uppercase
                                    cursor: pointer
                                    padding-right: 10px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 12px
                                    line-height: 14px
                                    display: flex
                                    align-items: center
                                    padding: 8px
                                    &.active
                                        background: #F8F9FA
                            .content_option_select
                                background: #F8F9FA
                                .content_modules
                                    padding: 10px
                                    .modules_container
                                        .list_modules
                                            padding: 0
                                            list-style: none
                                            .item_module
                                                display: flex
                                                align-items: center
                                                i
                                                    margin-right: 10px
                                                p
                                                    margin: 0
                                .content_certificate
                                    .progressbar_container
                                        width: 100%
                                        height: 100%
                                        margin-bottom: 10px
                                        .progressbar
                                            counter-reset: step
                                            margin-bottom: 10px
                                            li
                                                list-style-type: none
                                                width: 25%
                                                float: left
                                                font-size: 12px
                                                position: relative
                                                text-align: center
                                                text-transform: uppercase
                                                color: #7d7d7d
                                                &::after
                                                    width: 100%
                                                    height: 2px
                                                    content: ''
                                                    position: absolute
                                                    background-color: #7d7d7d
                                                    top: 15px
                                                    left: -50%
                                                    z-index: -1
                                                &::before
                                                    width: 30px
                                                    height: 30px
                                                    content: counter(step)
                                                    counter-increment: step
                                                    line-height: 30px
                                                    border: 2px solid #7d7d7d
                                                    display: block
                                                    text-align: center
                                                    margin: 0 auto 10px auto
                                                    border-radius: 50%
                                                    background-color: white
                                                &.active
                                                    color: var(--color-secondary)
                                                    &::before
                                                        border-color: var(--color-secondary)
                                                &:first-child
                                                    &::after
                                                        content: none
                                    .button_container
                                        margin-top: 110px
                                        button
                                            background-color: #fff
                                            text-align: center
                                            letter-spacing: 1.25px
                                            text-transform: uppercase
                                            color: var(--color-secondary)
                                            border: 1px solid var(--color-secondary)
                                            box-sizing: border-box
                                            border-radius: 12px
                                            padding: 8px
                                            cursor: pointer
                                            &:hover
                                                background-color: var(--color-secondary)
                                                color: #fff
                                                border: 1px solid var(--color-secondary)
                                    .previewCertificateContainer
                                        margin-top: 110px
                                        .image_certificate_container
                                            position: relative
                                            width: fit-content
                                            cursor: pointer
                                            .overlay
                                                position: absolute
                                                top: 0
                                                left: 0
                                                display: flex
                                                justify-content: center
                                                align-items: center
                                                width: 100%
                                                height: 100%
                                                i
                                                    color: #fff
                                                    font-size: 50px
                                        .button_container_certificate
                                            margin-top: 20px
                                            button
                                                background-color: #fff
                                                text-align: center
                                                letter-spacing: 1.25px
                                                text-transform: uppercase
                                                color: var(--color-secondary)
                                                border: 1px solid var(--color-secondary)
                                                box-sizing: border-box
                                                border-radius: 12px
                                                padding: 8px
                                                width: 240px
                                                cursor: pointer
                                                &:hover
                                                    background-color: var(--color-secondary)
                                                    color: #fff
