#root-portal
  background: rgba(20,22,24,0.75)
  position: fixed
  height: 100vh
  width: 100vw
  top: 0
  display: table
  z-index: 10
  animation: showModal 0.4s
  .contentModal
    display: table-cell
    text-align: center
    vertical-align: middle

@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1
