.main_noData
    display: grid
    justify-items: center
    width: 100%
    height: 100%
    align-content: center
    color: #7A8D9F
    font-family: sans-serif
    grid-row-gap: 5px
    text-align: center
    font-size: 14px
    font-weight: bold
    padding: 1rem 0
    div:nth-child(2)
        font-size: 20px
        font-weight: 900