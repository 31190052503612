.main_AddEntity
    padding: 10px
    width: 100%
    max-width: 488px
    display: inline-block
    .heaEntity
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        // justify-content: center
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #CDD4DB
            cursor: pointer
    .bodyEntity
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 0
        display: grid
        grid-row-gap: 15px
        .barNav
            width: 100%
            display: flex
            justify-content: center
            .optNav
                display: grid
                grid-template-columns: repeat(2, 70px)
                grid-column-gap: 3px
                span
                    background: #C4C4C4
                    color: #fff
                    // cursor: pointer
                    &.select
                        background: var(--color-secondary)
                    &:first-child
                        border-radius: 12px 0 0 12px
                    &:last-child
                        border-radius: 0 12px 12px 0
            .optNavEdit
                display: grid
                grid-template-columns: repeat(3, 70px)
                grid-column-gap: 3px
                span
                    background: #C4C4C4
                    color: #fff
                    // cursor: pointer
                    &.select
                        background: var(--color-secondary)
                    &:first-child
                        border-radius: 12px 0 0 12px
                    &:last-child
                        border-radius: 0 12px 12px 0

        .contFormEnt
            display: grid
            grid-auto-flow: row
            grid-row-gap: 15px
            text-align: left
            font-size: 14px
            color: #908C8C
            grid-auto-rows: max-content
            .sepContAddEnt
                display: grid
                grid-template-columns: repeat(2, 1fr)
                grid-column-gap: 16px
            .contInAddEnt
                width: 100%
                position: relative
                &.image
                    height: 170px

                .imgPort
                    width: 100%
                    height: calc(100% - 20px)
                    border-radius: 12px
                .imgPortada
                    width: 100%
                    height: calc(100% - 20px)
                    background-color: #C4C4C4
                    border-radius: 12px
                .imgPerf
                    width: 64px
                    height: 64px
                    background: var(--color-secondary)
                    border-radius: 50%
                    position: absolute
                    bottom: 0
                    left: 30px
                    display: flex
                    justify-content: center
                    align-items: center
                    &.active
                        background: #fff
                    div:first-child
                        display: grid
                        place-items: center
                        color: #fff
                        i
                            font-size: 24px
                p
                    margin: 0 0 5px 0
                    color: #000
                .title
                    color: var(--color-secondary)
                input, select
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    font-size: 14px
                    font-family: sans-serif
                    background: white
                textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    max-height: 100px
                    min-height: 100px
                select.half
                    width: calc(50% - 8px)
                .button_ent
                    padding: 0.5rem
                    border-radius: 10px
                    cursor: pointer
                    width: 100%
                    border: none
                    color: #fff
                    text-align: center
                    background: var(--color-secondary)
                .icon_password
                    position: absolute
                    bottom: 10px
                    right: 10px
                    color: rgba(0, 0, 0, 0.65)
            .optsBtnAdd
                display: grid
                grid-template-columns: 100px 100px
                justify-content: right
                grid-column-gap: 10px
                padding: 0 28px
                .containerSpinner
                    border: none
                    border-radius: 10px
                    width: 100%
                    // padding: 0.5rem
                    color: #ffffff
                    font-size: 10px
                    background: #1FC1C0
                    text-align: center
                    height: min-content
                    justify-content: center
                    display: flex
                    align-items: center
                    text-align: center
                    padding: 0.1rem
                    border-radius: 10px
                    cursor: pointer
                .otherButtons
                    text-align: center
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)
                button
                    text-align: center
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)
            &.scrollBody
                height: 400px
                // max-height: 400px
                overflow-y: auto
                padding: 0 28px

.imgPhotoEntity
    position: absolute
    bottom: 0
    left: 0px
    width: 64px
    height: 64px
    border-radius: 50%
    cursor: pointer

.main_DeleteEntity
    padding: 10px
    width: 100%
    max-width: 488px
    display: inline-block
    position: relative
    .iconDel
        font-size: 24px
        position: absolute
        right: 18px
        top: 18px
        color: #CDD4DB
        cursor: pointer
    .bodyDeletEntity
        background: #fff
        padding: 10px
        border-radius: 15px
        i
            color: #1FC1C0
            font-size: 32px
        h3
            font-size: 20px
            margin: 15px 0
        p
            color: #908C8C
        button
            padding: 0.5rem
            border-radius: 10px
            cursor: pointer
            border: none
            color: #fff
            background: #1FC1C0
            width: 100px
        .containerSpinner_center
            text-align: center
            display: flex
            justify-content: center
            .containerSpinner
                border: none
                border-radius: 10px
                width: 100px
                // padding: 0.5rem
                color: #ffffff
                font-size: 10px
                background: #1FC1C0
                text-align: center
                height: min-content
                justify-content: center
                display: flex
                align-items: center
                text-align: center
                padding: 0.1rem
                border-radius: 10px
                cursor: pointer
