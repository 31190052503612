.main_DetailsUser
    position: relative
    display: grid
    grid-auto-flow: column
    grid-auto-columns: max-content
    justify-content: right
    grid-column-gap: 5px
    font-size: 18px
    color: #B3B3B3
    height: 50px
    padding-right: 30px
    width: auto
    .contOptionUser
        display: grid
        align-items: center
        justify-content: center
        padding: 0 7px
        border: 1px solid
        border-radius: 20px
    .contOptionUserInfo
        display: grid
        // grid-template-columns: max-content max-content auto max-content
        grid-template-columns: max-content max-content
        grid-column-gap: 10px
        z-index: 2
        background: #fff
        width: auto
        align-items: center
        justify-content: center
        padding: 0 10px
        border: 1px solid
        border-radius: 20px
        position: relative
        .cntPrf
            display: flex
            align-items: center
            column-gap: 10px
            cursor: pointer
        &:hover
            // cursor: pointer
        .Userprofile
            // background-color: rgb(240, 169, 46)
            width: 35px
            height: 35px
            border-radius: 50%
        .nameDUser
            text-transform: lowercase
            font-size: 16px
            color: #000
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
            width: 130px
        i
            cursor: pointer
            &:first-child
                margin-right: 8px
        .nesSeption
            position: absolute
            right: 0
            top: 40px
            z-index: 1
            padding-top: 20px
        .optSelectOption
            // position: absolute
            // right: 0
            // top: 40px
            width: 250px
            font-size: 14px
            border: 1px solid
            // z-index: 1
            background: #fff
            align-items: center
            border-radius: 10px
            > a:first-child
                .onlyOpt
                    &:hover
                        border-radius: 10px 10px 0 0
            .onlyOpt
                color: #6b6b6b
                cursor: pointer
                width: 100%
                padding: 7px
                display: grid
                grid-template-columns: max-content auto 20px
                align-items: center
                grid-column-gap: 10px
                i
                    font-size: 24px
                &:hover
                    background: #DEEDFD
                    // border-radius: 10px;
                    color: #0972FF
            // .lineceparate
            .contDarkMode
                padding: 10px 7px
                display: flex
                gap: 9px
                justify-content: center
                .switch
                    background: #343D5B
                    border-radius: 1000px
                    border: none
                    position: relative
                    cursor: pointer
                    display: flex
                    outline: none
                    &.active
                        background: orange
                        &::after
                            left: unset
                            right: 0
                    &::after
                        content: ""
                        display: block
                        width: 25px
                        height: 25px
                        position: absolute
                        background: #F1F1F1
                        top: 0
                        left: 0
                        right: unset
                        border-radius: 100px
                        transition: .3s ease all
                        box-shadow: 0px 0px 2px 2px rgba(0,0,0,.2)
                    span
                        width: 23px
                        height: 23px
                        line-height: 23px
                        display: block
                        background: none
                        color: #fff
.show-more
    display: flex
    justify-content: center
    color: var(--color-secondary)
    font-weight: bold
    padding: 1rem 0.5rem
    span
        &:hover
            cursor: pointer
            opacity: 0.5

@media screen and ( max-width: 475px )
    .main_DetailsUser
        .contOptionUserInfo
            .nameDUser
                width: 0
