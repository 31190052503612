.titleOption
    display: flex
    justify-content: space-between
    .left_title
        display: flex
        margin-bottom: 20px
        width: 100%
        max-width: 400px
        justify-content: space-between
    .icon_dropdown
        cursor: pointer
        padding: 0 10px
.option_body
    margin-bottom: 20px
    .sections
        .section_item
            display: flex
            justify-content: space-between
            margin-bottom: 12px

.itemSubTitleOption
    display: flex

.itemInteractiveOption
    display: flex
