.first_row_moduleCourse
    background-color: #ffffff
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    padding: 10px 25px
    display: flex
    column-gap: 10px
    i
        cursor: pointer
.content_ModuleCourse
    display: grid
    height: 100%
    grid-template-rows: max-content max-content auto
    grid-row-gap: 10px
    overflow-y: auto
    .second_row_moduleCourse
        background-color: #ffffff
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        padding: 10px 25px
        display: flex
        .left_content
            width: 200px
            align-items: center
            display: flex
            img
                width: 150px
                height: 150px
                border-radius: 50%
                object-fit: contain
        .right_content
            width: calc( 100% - 250px )
            display: flex
            justify-content: center
            flex-direction: column
            p
                margin: 0
                margin-bottom: 10px
                &:first-child
                    text-transform: uppercase
                    color: #CDD4DB
                    letter-spacing: 1px
                &:nth-child(2)
                    text-transform: capitalize
                    font-weight: bold
                    font-size: 16px
                &:nth-child(3)
                    letter-spacing: 0.25px
                    color: rgba(0, 0, 0, 0.45)
                &:nth-last-child
                    letter-spacing: 1.25px
                    text-transform: uppercase
                    color: #19191D
    .third_row_moduleCourse
        display: grid
        grid-template-columns: 200px auto
        width: 100%
        grid-column-gap: 10px
        overflow-y: auto
        .first_column
            width: 100%
            height: 100%
            position: relative
            background-color: #ffffff
            overflow-y: auto
            p
                margin: 0
                text-align: center
                padding-top: 15px
                color: #19191D
                font-weight: bold
            .button_column
                text-align: center
                background: #F5F5F5
                border-radius: 5px
                color: var(--color-secondary)
                margin: 15px 25px
                padding: 15px
                cursor: pointer
                font-size: 14px
                font-family: 'Roboto'
                font-weight: 500
            .module_content
                height: auto
                top: 120px
                bottom: 0
                width: 100%
                p
                    text-transform: capitalize
                    color: #19191D
                    font-weight: 500
                    padding: 0
                .circle_module
                    display: flex
                    justify-content: space-evenly
                    align-items: center
                    margin-top: 8px
                    padding-top: 8px
                    padding-bottom: 8px
                    margin: 10px 35px 0px 35px
                    font-size: 14px
                    i
                        font-size: 16px
                    .menu_circle_module
                        color: #000
                        margin-left: 5px
                        position: relative
                        .optSelectOption
                            position: absolute
                            right: 20px
                            color: #000
                            top: 0px
                            width: 110px
                            font-size: 14px
                            z-index: 1
                            align-items: flex-start
                            background: #FFFFFF
                            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
                            border-radius: 5px
                            .onlyOpt
                                color: #000000
                                cursor: pointer
                                width: auto
                                padding: 7px
                                display: grid
                                grid-template-columns: auto
                                align-items: center
                                grid-column-gap: 10px
                                i
                                    font-size: 16px
                                &:first-child
                                    margin-top: 8px
                                &:last-child
                                    margin-bottom: 8px
                                &:hover
                                    background: #B3B3B3
        .second_column
            background-color: #ffffff
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            overflow-y: auto
            .content_text
                background: #F8F9FA
                border: 1px solid #CDD4DB
                box-sizing: border-box
                border-radius: 10px
                padding: 15px 30px
                margin: 15px 30px
            .content_materials
                padding: 15px 30px
                font-size: 14px
                // overflow-y: auto
                h5
                    margin: 0
                    font-size: 16px
                .row_container
                    display: grid
                    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr))
                    grid-gap: 1.5em
                    justify-content: center
                    width: 100%
                    margin-top: 15px
                    .card_type_file
                        display: flex
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        text-align: center
                        p
                            width: 100%
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                    .container_loading
                        width: 100%
                        height: 100%
                        display: flex
                        justify-content: center
                        align-items: center
            .button_container
                display: flex
                justify-content: flex-end
                padding-right: 30px
                padding-bottom: 30px
                .button_add_material
                    cursor: pointer
                    width: 194px
                    padding: 10px
                    background: var(--color-secondary)
                    border-radius: 5px
                    color: white
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 14px
                    font-family: 'Roboto'
                    i
                        margin-left: 10px
    .third_row_evaluations
        // display: grid
        width: 100%
        overflow-y: auto
        background-color: #FFF
        padding: 20px
