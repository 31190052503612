.Header_table
    display: flex
    justify-content: flex-end
    position: relative
    .button_search
        cursor: pointer
        width: 104px
        height: 32px
        background: #FFFFFF
        border: 1px solid #CCCCCC
        box-sizing: border-box
        border-radius: 5px
        font-size: 12px
        line-height: 14px
        letter-spacing: 1.25px
        color: #908C8C
        margin-right: 15px
        outline: none
    .input_search
        background: #FFFFFF
        border: 1px solid #CCCCCC
        box-sizing: border-box
        border-radius: 5px
        width: 500px
        height: 32px
        margin-right: 15px
        color: #908C8C
        font-size: 16px
        padding: 0 10px
        outline: none
    .fa-times
        cursor: pointer
        color: #908C8C
        position: relative
        right: 35px
        top: 7px
table
    font-size: 12px
    width: 100%
    border-collapse: separate
    border-spacing: 0px 10px
    position: relative
    thead
        th
            height: 40px
            font-size: 14px
            font-weight: 600
            padding: 8px
            border-top: 4px
            border-bottom: 1px
            border-radius: 5px
            color: #908C8C
            background: #ffffff
            &.th_Blu
                color: var(--color-primary)

        .tr_vacio
            height: 1px
            box-shadow: 0
            box-shadow: none
    tr
        border-radius: 5px
        color: #ffffff
        z-index: 1
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)

    td
        height: 52px
        text-align: center
        color: #908C8C
        background: #ffffff
        border-radius: 5px
        padding: 0
        max-width: 150px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        img
            width: 36px
            height: 36px
            border-radius: 50%
        i
            cursor: pointer
        .td_img_name
            display: flex
            align-items: center
            justify-content: center
            position: relative
            padding-left: 25px
            color: var(--color-primary)
            font-size: 14px
            .profileDef
                font-size: 36px
                margin-right: 15px
            img
                position: absolute
                left: 15px
    .menu_edit_elim
        width: 12px
        height: 52px
        background: var(--color-primary)
        top: -1px
        left: 0
        border-radius: 5px 0 0 5px
        transition: width 0.3s
        &.disabled
            pointer-events: none
        i
            display: none
            color: var(--color-primary)
            font-size: 14px
            background: #f6f6f6
            height: fit-content
            padding: 3px
            margin: 5px
            border-radius: 4px
            align-self: center
        &:hover
            width: 40px
            min-width: -webkit-fill-available
            display: flex
            justify-content: center
            transition: width 0.3s
            i
                display: block
    .menu_select_table
        width: 30px
        height: 52px
        background: var(--color-primary)
        border-radius: 5px 0 0 5px
        i
            color: #f6f6f6
            font-size: 20px
            height: fit-content
            margin: 16px 0
            border-radius: 4px
            align-self: center
.pagination_table
    display: flex
    position: relative
    justify-content: flex-end
    align-items: center
    width: 100%
    height: 52px
    background: #FFFFFF
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    padding: 0 30px
    color: #939393
    font-size: 18px
    .button
        border-radius: 50%
        background: var(--color-primary)
        width: 25px
        height: 25px
        display: flex
        align-items: center
        cursor: pointer
        margin: 0 10px
        i
            font-size: 18px
            color: #ffffff
        .fa-angle-left
            padding-left: 7px
        .fa-angle-right
            padding-left: 9px
    .itemstopage
        font-style: normal
        font-weight: normal
        font-size: 16px
        .selectbox-source
            margin: 0 10px
            height: 25px
            font-size: 16px
            border: solid 1px #939393
            outline: none

.text_show_student
    margin-right: 10px
.icon_show_student
    font-size: 14px
    border: 1px solid #0E3873
    border-radius: 10px
    padding: 5px
    color: var(--color-primary)
    &.active
        background: var(--color-primary)
        color: #fff
.content_actions_certificate
    display: flex
    align-items: center
    justify-content: space-around
    .img_certificate
        width: 24px
        height: 24px
        border-radius: 0px
    .icon_certificate
        font-size: 20px
.view_student
    a
        padding: 5px
        background: #EDEDED
        border-radius: 5px
        margin-left: 5px
.container_state
    display: flex
    justify-content: center
    .state_student_button
        display: flex
        justify-content: center
        width: 80%
        background-color: var(--color-primary)
        color: #ffffff
        border-radius: 5px
        padding-top: 5px
        padding-bottom: 5px
        // .text_state_student
