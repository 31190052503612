.head_content_user_enrollment
    // h2
    .search_container_enrollment
        position: relative
        width: 55%
        input
            background: #F8F9FA
            border-radius: 16px
            border: none
            padding: 10px
            width: 100%
        i
            position: absolute
            top: 8px
            right: 10px
            color: #CDD4DB
.body_content_user_enrollment
    overflow-y: auto
    height: 81vh
    .info_user
        margin-top: 20px
        padding: 10px
        display: flex
        background: #F8F9FA
        width: 55%
        justify-content: space-between
        flex-direction: column
        .img_photo
            width: 40px
            height: 40px
            margin-right: 20px
            border-radius: 50%
            .profileDef
                font-size: 39px
        .data_info
            width: 100%
            display: flex
            justify-content: space-between
            .data_first_part
                display: flex
                .data_info_name
                    p
                        text-align: left
                        margin: 0
                        &:first-child
                            text-transform: capitalize
            .assing_name
                display: flex
                justify-content: center
                align-items: center
                cursor: pointer
                .button_center
                    border: 1px solid var(--color-secondary)
                    box-sizing: border-box
                    border-radius: 12px
                    color: var(--color-secondary)
                    padding: 2px 8px
                    transition: 0.3s
                    &:hover
                        color: #fff
                        border: 1px solid var(--color-secondary)
                        background-color: var(--color-secondary)
        .detailEnrollment
            .content_detail_body
                width: 65%
                .radio_options
                    .radio_option
                        margin-top: 5px
                .input_groups
                    form
                        .sepContAddUser
                            display: grid
                            grid-template-columns: repeat(2, 1fr)
                            grid-gap: 16px
                        .contInAddUser
                            input
                                text-transform: capitalize
                                width: 100%
                                border: 1px solid #CDD4DB
                                box-sizing: border-box
                                border-radius: 12px
                                height: 38px
                        .container_button
                            width: 100%
                            margin-top: 20px
                            padding-bottom: 20px
                            .design_button
                                width: 100%
                                cursor: pointer
                                background: var(--color-secondary)
                                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14)
                                border-radius: 5px
                                border: none
                                padding: 10px
                                color: #fff
                                opacity: 1
                                transition: 0.3s
                                &:hover
                                    opacity: 0.8
