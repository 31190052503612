.modalUsers
    position: absolute
    display: block
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)
    z-index: 5
    ::-webkit-scrollbar
        display: none
    .modal-content_user
        width: 40%
        height: 40%
        background-color: #ffffff
        border-radius: 22px
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
        animation-name: animatetop
        animation-duration: 0.4s
        overflow-y: hidden
        margin: auto
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        .modal-header_user
            position: relative
            display: flex
            justify-content: space-between
            background-color: #1FC1C0
            color: #fff
            padding: 15px 35px
            font-size: 30px
            align-items: center
        .modal-body_user
            color: #908C8C
            width: 100%
            position: absolute
            height: auto
            top: 70px
            bottom: 0
