.entity {
    &__container {
        background-color: var(--color-light);
        border-radius: 10px;
        padding: 22px;
        margin-right: 30px;
        display: grid;
        transition: background-color 0.3s;
        grid-row-gap: 10px;
        overflow-y: auto;
    }
    &__img {
        object-fit: contain;
    }
}
