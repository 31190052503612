.main_UsuariosB2bPage
    display: grid
    grid-template-rows: 50px auto
    grid-row-gap: 20px
    overflow-y: auto
    width: 100%
    .headUsuariosB2b
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
    .bodyUsuariosB2b
        overflow-y: auto
        overflow-x: hidden
        display: grid
        grid-auto-flow: row
        grid-row-gap: 10px
        padding-right: 30px
        .cardEntity_container
            border-radius: 24px
            background-color: #fff
            padding: 20px
            .cardEntity_content
                background-color: #fff
                padding: 20px
            .cardEntity_body
                display: flex
                justify-content: space-between
                background-color: #fff
                padding: 20px
