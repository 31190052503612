.containerAccess
    display: grid
    grid-template-rows: 55px auto
    grid-row-gap: 30px
    border: 1.5px solid #DADCE0
    border-radius: 16px
    padding: 2rem
    width: 100%
    max-width: 400px
    box-sizing: border-box
    overflow-y: auto
    img
        height: 55px
        max-width: 320px
    .contAccess
        display: grid
        grid-auto-flow: row
        grid-auto-rows: max-content
        grid-row-gap: 18px
        .titleAccess
            font-size: 25px
            letter-spacing: 0.25px
            font-weight: 700
            font-family: system-ui
            div:nth-child(2)
                color: #197BFF
                line-height: 22px
                letter-spacing: 1.5px
        .infoAccess
            background-color: #8CBDFF
            border-radius: 12px
            padding: 12px
            font-size: 11px
            font-weight: 700
            .infTit
                font-size: 13px
                font-family: 'Roboto'
                letter-spacing: 0.25px
            .infHelp
                text-align: right
                color: #197BFF
                letter-spacing: 1.5px
                span
                    cursor: pointer
