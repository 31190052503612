.lds-ringModal
    display: flex
    position: relative
    width: 35px
    height: 35px
    justify-content: center
    align-items: center
    .one
        animation-delay: -0.45s
    .two
        animation-delay: -0.3s
    .three
        animation-delay: -0.15s
    div
        box-sizing: border-box
        display: block
        position: absolute
        width: 28px
        height: 28px
        margin: 8px
        border: 8px solid #fff
        border-radius: 50%
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
        border-color: #fff transparent transparent transparent

@keyframes lds-ringModal
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
