.main_Error
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    background: #fff
    .contError
        display: grid
        grid-auto-flow: column
        align-items: center
        justify-content: center
        grid-column-gap: 70px
        .contMessg
            display: grid
            justify-items: center
            height: 100%
            grid-template-rows: repeat(3, max-content)
            align-content: center
            grid-row-gap: 15px
            div
                &:first-child
                    font-family: system-ui
                    font-style: normal
                    font-weight: bold
                    font-size: 72px
                    line-height: 84px
                &:nth-child(2)
                    font-family: Roboto
                    font-size: 24px
                    text-align: center
            button
                border-radius: 4px
                border: none
                background: #828282
                color: #fff
                padding: 0.5rem 1rem
                font-size: 16px