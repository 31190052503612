.main_AddUserCounter
    width: 100%
    max-width: 488px
    padding: 10px
    display: inline-block
    .headAddUserCounter
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        justify-content: center
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #CDD4DB
            cursor: pointer
    .bodyAddUserCounter
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 0
        display: grid
        grid-row-gap: 15px
        .contPhotoUser
            display: flex
            justify-content: center
            .contImgUser
                display: flex
                width: max-content
                gap: 18px
                align-items: center
                .imgDeforView
                    width: 63px
                    height: 63px
                    border-radius: 50%
                    background: var(--color-secondary)
                    display: flex
                    align-items: center
                    justify-content: center
                    i
                        color: #fff
                        font-size: 22px
                label
                    cursor: pointer
                    color: var(--color-secondary)
                    font-size: 14px
                    input
                        display: none
        .contFormCour
            display: grid
            grid-auto-flow: row
            grid-row-gap: 15px
            text-align: left
            font-size: 14px
            color: #908C8C
            &.scrollBody
                height: auto
                max-height: 300px
                overflow-y: auto
                padding: 0 28px
            .sepContAddUser
                display: grid
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 16px
            .contInAddUser
                width: 100%
                p
                    margin: 0 0 5px 0
                    color: #000
                input, select
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                select
                    cursor: pointer
            .optsBtnAdd
                display: grid
                grid-template-columns: 100px 100px
                justify-content: right
                grid-column-gap: 10px
                padding: 0 45px
                .containerSpinner
                    background: var(--color-secondary)
                    border: none
                    border-radius: 10px
                    width: 100%
                    // padding: 0.5rem
                    color: #ffffff
                    font-weight: 500
                    font-size: 14px
                    text-align: center
                    justify-content: center
                    display: flex
                    align-items: center
                    // padding: 0 28px
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)
.content_inputs_flex
    display: flex
    width: 100%
    justify-content: space-between
    .container_input_content_MUC
        width: 45%

.container_input_content_MUC
    width: 100%
    text-align: start
    margin-bottom: 15px
    .container_centrar
        text-align: center
        span
            text-align: center
            color: var(--color-secondary)
            font-size: 14px
            cursor: pointer
    label
        margin-bottom: 10px
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 14px
        color: #19191D
    .input_design_content_MUC
        width: 100%
        height: 33px
        background: #FFFFFF
        border: 1px solid #D8CCCC
        box-sizing: border-box
        border-radius: 10px
        margin-top: 10px
        padding-left: 15px
        &::placeholder
            color: #737a80

.container_input_content_DSC
    display: flex
    width: 100%
    text-align: start
    margin-bottom: 15px
    .input_design_content_DSC
        width: 80%
        height: 33px
        background: #FFFFFF
        border: 1px solid #D8CCCC
        box-sizing: border-box
        border-bottom-left-radius: 5px
        border-top-left-radius: 5px
        border: 0.5px solid #0E3873
        padding-left: 15px
    .btn_discount
        width: 20%
        height: 33px
        background-color: var(--color-secondary)
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px
        color: #ffffff
        border: 0.5px solid #0E3873
        display: flex
        justify-content: center
        align-items: center

.container_button_content_MUC
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 5px
    background: var(--color-secondary)
    height: 33px
    color: #fff
    border: 0.5px solid #D3DCE6
    border-radius: 10px
    margin-top: 5px

@media screen and ( max-width: 510px )
    .main_AddUserCounter
        .bodyAddUserCounter
            .newForm
                .sepCont
                    grid-template-columns: none
                    grid-gap: 0
                    overflow-y: auto
                    height: 450px
                    padding-right: 15px
                .buttons_container
                    padding-top: 10px
