html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
        Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: #f6f6f6;
}

a {
    color: inherit;
    text-decoration: none;
}

/* label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #19191d;
} */

.contInAddEnt p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #19191d;
}

.contInAddFile p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #19191d;
}

.contInAddCour p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #19191d;
}

textarea,
input,
select {
    font-family: 'Roboto';
    font-size: 13px;
    resize: none;
}

* {
    box-sizing: border-box;
    outline: none;
}

:root {
    --color-primary: #4a95ff;
    --color-secondary: #4a95ff;
    --color-font: #ffffff;
}

/* darkMode */
:root {
    --color-dashboard: #f5f5f5;
    --color-light: #ffffff;
    --color-dark: #000000;
}

.contBtn {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-column-gap: 10px;
    width: auto;
    margin-bottom: 10px;
}
.contBtn .addOption {
    width: max-content;
    background-color: var(--color-secondary);
    border-radius: 5px;
    color: #ffffff;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Roboto';
    border: none;
}

.contBtn .addOption:hover {
    opacity: 0.8;
}

button[disabled],
input[disabled] {
    cursor: no-drop !important;
    opacity: 0.5;
}

.disabled {
    cursor: no-drop !important;
    opacity: 0.5;
}
.disabled div {
    pointer-events: none;
}
