.container_dashboard
    width: 100%
    height: 100%
    display: flex
    background-color: var(--color-dashboard)
    transition: background-color 0.3s
    .hambImg
        position: absolute
        top: 27px
        left: 30px
        display: none
        justify-content: center
        align-items: center
        grid-column-gap: 10px
        padding: 0.4rem 1rem
        border-radius: 16px
        background-color: var(--color-primary)
        z-index: 1
        i
            font-size: 25px
            color: var(--color-font)
            cursor: pointer
        img
            height: 30px
    .sidebar_container
        width: 200px
        height: 100vh
        background-color: var(--color-primary)
        transition: all 0.3s
        z-index: 2
        display: grid
        grid-template-rows: max-content max-content auto
        &.active
            background-color: #233044
        &.show
            left: 0
        .closeSide
            position: absolute
            width: 40px
            height: 40px
            left: 160px
            display: none
            align-items: center
            justify-content: center
            font-size: 25px
            color: var(--color-font)
            z-index: 1
            i
                cursor: pointer
        .logo_sidebar
            padding: 0.6rem 1.5rem
            display: flex
            justify-content: center
            position: relative
            align-items: center
            max-width: 200px
            &.tras
                background: rgba(0,0,0,.27)
            .logo_sidebar_content
                display: flex
                align-items: center
                grid-column-gap: 10px
                img
                    width: auto
                    max-width: 160px
                    height: 75px
            .menuOptionsEntity
                position: absolute
                right: 15px
                cursor: pointer
                .arrow_choose
                    color: var(--color-font)
                    display: flex
                    flex-direction: column
                    justify-content: center
            .option_role_menu
                background-color: white
                position: absolute
                top: 62px
                width: 100%
                left: 0
                .container_role
                    align-items: center
                    padding: 15px
                    display: flex
                    cursor: pointer
                    width: 100%
                    height: 100px
                    .logo_role
                        width: 100%
                        height: 100%
                        display: flex
                        justify-content: center
                        align-items: center
                        border-radius: 10px
                        padding: 10px
                        &:hover
                            background-color: lightgray
                        .imgPhoto
                            width: 100px
                            height: 100%
        .container_choose_role
            background-color: rgba(255, 255, 255, 0.12)
            padding: 0 15px
            height: 62px
            display: flex
            align-items: center
            margin-bottom: 40px
            position: relative
            cursor: pointer
            .content_choose
                display: flex
                justify-content: space-between
                color: var(--color-font)
                width: 100%
                .image_text_choose
                    display: flex
                    align-items: center
                    .image_role
                        background-color: #FFB800
                        border-radius: 50%
                        width: 29px
                        height: 29px
                        display: flex
                        align-items: center
                        justify-content: center
                        span
                            color: #000
                            font-weight: 500
                    .text_role
                        display: flex
                        flex-direction: column
                        margin-left: 15px
                        span
                            text-transform: capitalize
                .arrow_choose
                    display: flex
                    flex-direction: column
                    justify-content: center
            .option_role_menu
                background-color: white
                position: absolute
                top: 55px
                width: 100%
                left: 0
                filter: drop-shadow(rgba(38, 50, 56, 0.16) 0px 2px 4px) drop-shadow(rgba(38, 50, 56, 0.08) 0px 4px 8px)
                height: calc(100vh - 149px)
                background: inherit
                backdrop-filter: blur(2px)
                .container_role
                    align-items: center
                    padding: 0 15px
                    height: 62px
                    cursor: pointer
                    background-color: #fff
                    .image_role
                        background-color: #FFB800
                        border-radius: 50%
                        width: 29px
                        height: 29px
                        display: flex
                        align-items: center
                        justify-content: center
                        span
                            color: #000
                            font-weight: 500
                    .text_role
                        display: flex
                        flex-direction: column
                        margin-left: 15px
                    &:hover
                        background-color: #f5f5f5
        .container_nav
            color: #ffffff
            display: flex
            justify-content: center
            font-size: 16px
            .list_nav
                list-style: none
                width: 100%
                margin: 0
                padding: 0
                .list_nav_item
                    padding: 10px
                    .link_sidebar
                        width: 100%
                        padding: 7px 20px
                        display: grid
                        grid-template-columns: 25px auto
                        grid-column-gap: 15px
                        color: var(--color-font)
                    .active
                        width: 100%
                        border-radius: 45px
                        background: var(--color-font)
                        color: var(--color-primary)
                        text-decoration: none
                    i
                        display: flex
                        align-items: center
                        justify-content: center
    .content_dashboard
        width: calc( 100vw - 200px )
        padding: 30px 0 0 30px
        height: 100vh
        display: grid
        grid-template-rows: max-content auto
        grid-row-gap: 20px

//responsive sidebar
@media screen and ( max-width: 865px )
    .container_dashboard
        .hambImg
            display: flex
        .sidebar_container
            position: absolute
            top: 0
            bottom: 0
            left: -200px
            .closeSide
                display: flex
        .content_dashboard
            width: 100vw
