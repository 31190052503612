.main_EditStudent
    width: 488px
    display: inline-block
    .headEditStud
        background: var(--color-primary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #CDD4DB
            cursor: pointer
    .bodyEditStud
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 28px
        display: grid
        grid-row-gap: 20px
        .contOption
            width: 100%
            text-align: start
            label
                margin-bottom: 10px
                font-style: normal
                font-weight: normal
                font-size: 18px
                line-height: 14px
                color: #8492A6
                display: block
        .contListCourses
            display: flex
            flex-wrap: wrap
            gap: 6px
            justify-content: space-evenly
            .itemCourse
                width: max-content
                padding: 0.25rem 0.5rem
                background: var(--color-primary)
                color: #fff
                border-radius: 8px
                font-size: 15px
                display: grid
                grid-auto-flow: column
                grid-column-gap: 5px
                align-items: center
                i
                    cursor: pointer
        button
            cursor: pointer
            background: var(--color-secondary)
            border-radius: 15px
            border: none
            font-size: 15px
            line-height: 14px
            letter-spacing: 1.25px
            color: #FFFFFF
            padding: 10px 25px
            width: 167px
            justify-self: center
