.main_AddUser
    width: 100%
    max-width: 488px
    padding: 10px
    display: inline-block
    .headAddUser
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        justify-content: center
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #fff
            cursor: pointer
    .bodyAddUser
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 0
        display: grid
        grid-row-gap: 15px
        .contPhotoUser
            display: flex
            justify-content: center
            .contImgUser
                display: flex
                width: max-content
                gap: 18px
                align-items: center
                .imgDeforView
                    width: 63px
                    height: 63px
                    border-radius: 50%
                    background: var(--color-secondary)
                    display: flex
                    align-items: center
                    justify-content: center
                    i
                        color: #fff
                        font-size: 22px
                label
                    cursor: pointer
                    color: var(--color-secondary)
                    font-size: 14px
                    input
                        display: none
        .contFormCour
            display: grid
            grid-auto-flow: row
            grid-row-gap: 15px
            text-align: left
            font-size: 14px
            color: #908C8C
            &.scrollBody
                height: auto
                max-height: 300px
                overflow-y: auto
                padding: 0 28px
            .sepContAddUser
                display: grid
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 16px
            .contInAddUser
                width: 100%
                position: relative
                p
                    margin: 0 0 5px 0
                    color: #000
                input, select, textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                select
                    cursor: pointer
                i
                    position: absolute
                    right: 10px
                    top: 34px
                    z-index: 2
            .optsBtnAdd
                display: grid
                grid-template-columns: 100px 100px
                justify-content: right
                grid-column-gap: 10px
                padding: 0 45px
                .containerSpinner
                    background: var(--color-secondary)
                    border: none
                    border-radius: 10px
                    width: 100%
                    // padding: 0.5rem
                    color: #ffffff
                    font-weight: 500
                    font-size: 14px
                    text-align: center
                    justify-content: center
                    display: flex
                    align-items: center
                    // padding: 0 28px
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid var(--color-secondary)
                    color: var(--color-secondary)
                    cursor: pointer
                    background: inherit
                    height: 35px
                    display: flex
                    align-items: center
                    justify-content: center
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)

.main_DeleteUser
    width: 488px
    display: inline-block
    position: relative
    padding: 10px
    width: 100%
    max-width: 488px
    .iconDel
        font-size: 24px
        position: absolute
        right: 18px
        top: 18px
        color: #CDD4DB
        cursor: pointer
    .bodyDeletUse
        background-color: #fff
        border-radius: 15px
        padding: 10px
        i
            color: var(--color-secondary)
            font-size: 32px
        h3
            font-size: 20px
            margin: 15px 0
        p
            color: #908C8C
        button
            padding: 0.5rem
            border-radius: 10px
            cursor: pointer
            border: none
            color: #fff
            background: var(--color-secondary)
            width: 100px

@media screen and ( max-width: 425px )
    .main_AddUser
        .bodyAddUser
            .contFormCour
                .sepContAddUser
                    grid-template-columns: none
