.courses_container_page
    width: 100%
    overflow-y: auto
    padding-right: 30px
    display: flex
    flex-direction: column
    row-gap: 20px
    .course_content_page
        display: grid
        grid-template-columns: 281px auto
        width: 100%
        grid-column-gap: 15px
        height: 100%
        .left_categories_container
            background-color: #ffffff
            height: 100%
            border-top-left-radius: 10px
            border-bottom-left-radius: 10px
            overflow-y: auto
            display: grid
            grid-template-rows: max-content max-content auto
            padding: 18px
            grid-row-gap: 18px
            font-family: 'Roboto'
            font-size: 14px
            .search_box_categories
                background: #F8F9FA
                border: 1px solid #FFFFFF
                box-sizing: border-box
                border-radius: 16px
                display: flex
                height: 44px
                align-items: center
                padding: 0px 20px
                justify-content: space-between
                .text_search_categories
                    font-style: normal
                    font-weight: 500
                    font-size: 13px
                    line-height: 15px
                    text-align: center
                    letter-spacing: 0.15px
                    color: #D6D6D6
                    width: 75%
                    input
                        width: 100%
                        height: 44px
                        background-color: inherit
                        border: none
                .icons_search_categories
                    color: #D6D6D6
                    .fa-filter
                        margin-right: 15px
            .button_add_categories
                background: var(--color-secondary)
                border: 1px solid var(--color-secondary)
                box-sizing: border-box
                border-radius: 16px
                display: flex
                height: 44px
                align-items: center
                padding: 0px 12px
                justify-content: space-between
                color: #ffffff
                cursor: pointer
            .container_categories_text
                overflow-y: auto
                .item_course
                    cursor: pointer
                    padding: 10px
                    display: grid
                    grid-template-columns: 175px 15px
                    justify-content: space-between
                    align-items: center
                    border-radius: 12px
                    background-color: #fff
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04)
                    opacity: 0.8
                    margin-bottom: 20px
                    &.active
                        background-color: #F8E5F0
                    .image_item
                        width: 100%
                        height: 100%
                        .content_image_item
                            width: 100%
                            height: 100%
                            display: flex
                            border-radius: 6px
                            justify-content: center
                            align-items: center
                            background-color: #fff
                            color: var(--color-secondary)
                    .info_item
                        width: 100%
                        // padding-left: 10px
                        p
                            width: 100%
                            margin: 0
                            text-transform: capitalize
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                            &:first-child
                                font-weight: bold
                            &:last-child
                                color: #757f88
                    .arrow_item
                        width: 100%
                        display: flex
                        align-items: center
                        justify-content: flex-end
                .content_categories_text
                    cursor: pointer
                    .text_categories
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        line-height: 14px
                        letter-spacing: 0.25px
                        color: #19191D
                        margin-bottom: 5px
                    .courses_text_categories
                        font-style: normal
                        font-weight: normal
                        font-size: 12px
                        line-height: 9px
                        letter-spacing: 1.5px
                        text-transform: uppercase
                        color: #CDD4DB
                        margin-bottom: 20px
        .rigth_courses_container
            height: 100%
            width: 100%
            // padding-right: 30px
            overflow-y: auto
            display: grid
            grid-template-rows: max-content auto
            grid-row-gap: 15px
            &.newClass
                grid-template-rows: auto
            .title_right_courses
                background-color: #ffffff
                // border-top-left-radius: 10px
                border-top-right-radius: 10px
                display: flex
                justify-content: space-between
                padding: 20px 25px
                align-items: center
                .text_container_courses
                    padding-right: 40px
                    span
                        text-transform: capitalize
                        font-size: 16px
                        font-weight: bold
                        overflow-wrap: anywhere
                        overflow: hidden
                        display: -webkit-box
                        -webkit-line-clamp: 1
                        -webkit-box-orient: vertical
                    .total_courses_text
                        margin-top: 10px
                        font-style: normal
                        font-weight: normal
                        font-size: 12px
                        line-height: 12px
                        letter-spacing: 1.5px
                        text-transform: uppercase
                        color: #CDD4DB
                        overflow-wrap: anywhere
                        overflow: hidden
                        display: -webkit-box
                        -webkit-line-clamp: 1
                        -webkit-box-orient: vertical
                .menu_container_courses
                    position: relative
                    .optSelectOption
                        position: absolute
                        right: 0px
                        top: -100vh
                        width: 180px
                        transform: scaleY(0)
                        transform-origin: center top
                        color: #000
                        width: 150px
                        font-size: 14px
                        z-index: 1
                        align-items: flex-start
                        background: #FFFFFF
                        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
                        border-radius: 5px
                        &.active
                            top: 20px
                            transition: transform .3s
                            transform: scale(1)
                            z-index: 3
                        .onlyOpt
                            color: #000000
                            cursor: pointer
                            width: 150px
                            padding: 7px
                            display: grid
                            grid-template-columns: auto
                            align-items: center
                            grid-column-gap: 10px
                            i
                                font-size: 24px
                            &:first-child
                                margin-top: 8px
                            &:last-child
                                margin-bottom: 8px
                            &:hover
                                background: #B3B3B3
            .body_right_courses
                // margin-top: 15px
                background-color: #ffffff
                padding: 20px
                width: 100%
                .containerSpinner
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 100%
