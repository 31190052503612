.container_UsuariosPage
    display: grid
    grid-template-rows: 50px calc(100% - 50px)
    width: 100%
    overflow-y: auto
    // height: calc( 100% - 36px )
    .headUserPag
        display: flex
        // align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            width: 800px
        div
            width: 120px
            display: flex
            align-items: center
            justify-content: space-between
    .bodyUserPag
        overflow-y: auto
        overflow-x: hidden
        display: grid
        grid-auto-flow: row
        grid-auto-rows: max-content
        grid-row-gap: 10px
            /* padding-right: 30px; */
        margin-top: 20px
        margin-right: 30px
        padding: 30px
        background: #fff
        .container_menu_usercounter
            width: 100%
            position: relative
            .content_info_menu
                display: flex
                margin-bottom: 55px
                .first_item_menu
                    width: 200px
                    cursor: pointer
                    .first_item_divider
                        height: 8px
                        width: 200px
                        background-color: var(--color-secondary)
                        border-radius: 5px
                        margin-top: 10px
                        z-index: 5
                        position: absolute
                .second_item_menu
                    cursor: pointer
                    width: 300px
                    .second_item_divider
                        height: 8px
                        width: 300px
                        background-color: var(--color-secondary)
                        border-radius: 5px
                        margin-top: 10px
                        z-index: 5
                        position: absolute
            .line_divider_item
                position: absolute
                top: 177px
                width: calc( 100% - 255px )
                height: 8px
                background-color: #E6E6E6
                border-radius: 5px
                margin-top: 10px
        .content_second_page
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 25px
            padding: 1px
            .first_table_secondpage
                border-radius: 10px
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
                padding: 15px
            .second_table_secondpage
                border-radius: 10px
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
                padding: 15px
        .separator
            margin-top: 10px

@media screen and ( max-width: 865px )
    .container_UsuariosPage
        .bodyUserPag
            .content_second_page
                grid-template-columns: none
