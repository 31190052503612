.multiedu__button {
    width: max-content;
    background-color: var(--color-secondary);
    border-radius: 5px;
    color: #ffffff;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 14px;
    // font-family: "Roboto";
    border: none;
    // min-width: 32.8px;
    min-width: 150px;
    &:hover {
        opacity: 0.8;
    }
    &-icon {
        min-width: 32.8px;
    }
}
