.container_certificate
    width: 100%
    // height: 100%
    background-color: white
    .content_certificate_body
        display: grid
        grid-template-rows: min-content min-content auto
        grid-column-gap: 15px
        padding: 30px
        width: 100%
        p
            font-family: Roboto
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 16px
            letter-spacing: 0.5px
            color: #19191D
        span
            font-family: Roboto
            font-style: italic
            font-weight: 300
            font-size: 12px
            line-height: 14px
            letter-spacing: 0.5px
            color: #000000
            margin-top: 20px
        .listCertificate
            display: grid
            grid-template-columns: repeat(auto-fit, minmax(305px, 1fr))
            gap: 50px
            height: max-content
            margin-top: 40px
            .itemCerti
                width: 100%
                height: min-content
                cursor: pointer
                position: relative
                i
                    color: #0972FF
                    font-size: 40px
                    position: absolute
                    top: -14px
                    z-index: 1
                    left: 50%
                    background-color: #ffffff
                    border-radius: 20px
                &.active
                    border: 2px solid #0972FF
                    border-radius: 8px
                img
                    width: 100%
                    height: auto
                    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1))
                    border-radius: 8px
