.AddOption
    width: 161px
    height: 32px
    background: var(--color-secondary)
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    color: #ffffff
    display: flex
    justify-content: center
    padding: 4px
    cursor: pointer
.container_input_content_MUC
    &.center
        text-align: -webkit-center
    input, textarea
        outline: none
    .selectbox
        margin-top: 10px
    textarea
        height: 100px !important
    #fichero
        display: none
    .circle
        display: flex
        border-radius: 50%
        width: 100px
        height: 100px
        background-color: var(--color-secondary)
        cursor: pointer
        align-items: center
        justify-content: center
        i
            color: var(--color-light)
            font-size: 30px
.main_ModalCoursesScheduled
    width: 800px
    display: inline-block
    .headModalCoursesScheduled
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        justify-content: space-between
        position: relative
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #fff
            cursor: pointer
    .bodyModalCoursesScheduled
        background: #fff
        padding: 20px
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        overflow-x: auto
        max-height: 90vh
        form
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            .addUser_content_left
                width: 40%
                text-align: center
                display: flex
                flex-direction: column
                align-items: center
                .containerPhotoContent
                    border-radius: 50%
                    width: 150px
                    height: 150px
                    position: relative
                    margin-bottom: 20px
                    img
                        width: 150px
                        height: 150px
                        border-radius: 50%
                    .inputDesignPhotoContent
                        display: none
                    .designPhotoContent
                        i
                            position: absolute
                            bottom: 0px
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31))
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            color: #ffffff
                            border-radius: 50%
                            font-size: 25px
                            right: 0
                            padding: 13px
            .addUser_content_right
                width: 50%
            .buttons_container
                display: flex
                justify-content: flex-end
                align-items: center
                padding-top: 15px
                padding-bottom: 15px
                .cancelButton
                    font-weight: 500
                    font-size: 13px
                    line-height: 15px
                    letter-spacing: 0.1px
                    color: var(--color-secondary)
                    border: solid 1px var(--color-secondary)
                    margin-right: 18px
                    cursor: pointer
                    width: 100px
                    height: 32px
                    border-radius: 5px
                    display: flex
                    justify-content: center
                    align-items: center
                    background-color: inherit
                .saveButton
                    width: 100px
                    height: 32px
                    cursor: pointer
                    background: var(--color-secondary)
                    border-radius: 5px
                    color: #ffffff
                    border: none
                    font-size: 13px
                    line-height: 15px
                    letter-spacing: 1.1px
                    color: #FFFFFF
                    display: flex
                    justify-content: center
                    align-items: center
.content_inputs_flex
    display: flex
    width: 100%
    justify-content: space-between
    .container_input_content_MUC
        width: 45%
.input_required
    opacity: 0
    width: 100%
    height: 0
    position: absolute
.container_input_content_MUC
    width: 100%
    text-align: start
    margin-bottom: 15px
    position: relative
    .container_centrar
        text-align: center
        span
            text-align: center
            color: var(--color-secondary)
            font-size: 14px
            cursor: pointer
    label
        margin-bottom: 10px
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 14px
        color: #19191D
        font-family: "Roboto"
    .input_design_content_MUC
        width: 100%
        height: 33px
        background: #FFFFFF
        border: 1px solid #D8CCCC
        box-sizing: border-box
        border-radius: 10px
        margin-top: 10px
        padding-left: 15px
        &::placeholder
            color: #737a80

.container_input_content_DSC
    display: flex
    width: 100%
    text-align: start
    margin-bottom: 15px
    .input_design_content_DSC
        width: 80%
        height: 33px
        background: #FFFFFF
        border: 1px solid #D8CCCC
        box-sizing: border-box
        border-bottom-left-radius: 5px
        border-top-left-radius: 5px
        border: 0.5px solid #0E3873
        padding-left: 15px
    .btn_discount
        width: 20%
        height: 33px
        background-color: var(--color-secondary)
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px
        color: #ffffff
        border: 0.5px solid #0E3873
        display: flex
        justify-content: center
        align-items: center

.container_button_content_MUC
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 5px
    background: var(--color-secondary)
    height: 33px
    color: #fff
    border: 0.5px solid #D3DCE6
    border-radius: 10px
    margin-top: 5px
