.main_AddCategory
    padding: 10px
    width: 100%
    max-width: 488px
    display: inline-block
    .heaCategory
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        // justify-content: center
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #fff
            cursor: pointer
    .bodyCategory
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 0
        display: grid
        grid-row-gap: 15px
        .contFormEnt
            display: grid
            grid-auto-flow: row
            grid-row-gap: 15px
            text-align: left
            font-size: 14px
            padding: 0px 10px
            color: #908C8C
            grid-auto-rows: max-content
            .sepContAddEnt
                display: grid
                grid-template-columns: repeat(2, 1fr)
                grid-column-gap: 16px
            .contInAddEnt
                width: 100%
                position: relative
                &.image
                    height: 170px
                .imgPort
                    width: 100%
                    height: calc(100% - 20px)
                    background: #C4C4C4
                    border-radius: 12px
                .imgPerf
                    width: 64px
                    height: 64px
                    background: var(--color-secondary)
                    border-radius: 50%
                    position: absolute
                    bottom: 0
                    left: 30px
                    display: flex
                    justify-content: center
                    align-items: center
                    div:first-child
                        display: grid
                        place-items: center
                        color: #fff
                        i
                            font-size: 24px
                p
                    margin: 0 0 5px 0
                    color: #000
                .title
                    color: var(--color-secondary)
                input, select
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    max-height: 100px
                    min-height: 100px
                select.half
                    width: calc(50% - 8px)
                .button_ent
                    padding: 0.5rem
                    border-radius: 10px
                    cursor: pointer
                    width: 100%
                    border: none
                    color: #fff
                    text-align: center
                    background: var(--color-secondary)
                .title_label
                    display: grid
                    grid-template-columns: 1fr auto
                    height: 35px
                    .first_part
                        border: 1px solid var(--color-secondary)
                        padding: 7px
                        width: 100%
                        display: flex
                        justify-content: center
                        align-items: center
                        border-radius: 10px 0px 0px 10px
                    .second_part
                        box-sizing: border-box
                        background: var(--color-secondary)
                        border-radius: 0px 10px 10px 0px
                        width: 123px
                        align-items: center
                        display: flex
                        justify-content: center
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                        color: #fff
                        border: none
                    .containerSpinner
                        background: var(--color-secondary)
                        border: none
                        border-radius: 10px
                        width: 123px
                        border-radius: 0px 10px 10px 0px
                        color: #ffffff
                        font-weight: 500
                        font-size: 14px
                        text-align: center
                        justify-content: center
                        display: flex
                        align-items: center
                .file_label
                    display: grid
                    grid-template-columns: max-content auto
                    .first_part
                        background: var(--color-secondary)
                        border-radius: 10px 0px 0px 10px
                        padding: 7px
                        color: white
                        width: 123px
                        display: flex
                        justify-content: center
                        align-items: center
                    .input_design
                        display: none
                    .second_part
                        border: 1px solid var(--color-secondary)
                        box-sizing: border-box
                        border-radius: 0px 10px 10px 0px
                        width: 100%
                        align-items: center
                        display: flex
                        padding-left: 10px
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                        margin: 0
            .container_title
                width: 100%
                border: 1px solid
                height: 35px
                border-radius: 10px
                display: flex
                align-items: center
                .content_content
                    width: 100%
                    display: flex
                    justify-content: space-between
                    .content_title
                        background: #FEFEFE
                        border-radius: 15px 15px 15px 15px
                        padding-left: 10px
                        // .text_title
                    .options
                        position: relative
                        right: 5px
                        display: flex
                        align-items: center
                        justify-content: center

            .optsBtnAdd
                display: grid
                grid-template-columns: 100px 100px
                justify-content: right
                grid-column-gap: 10px
                padding: 0 10px
                .containerSpinner
                    background: var(--color-secondary)
                    border: none
                    border-radius: 10px
                    width: 100%
                    // padding: 0.5rem
                    color: #ffffff
                    font-weight: 500
                    font-size: 14px
                    text-align: center
                    justify-content: center
                    display: flex
                    align-items: center
                // div
                //     text-align: center
                //     padding: 0.5rem
                //     border-radius: 10px
                //     border: 1px solid #D8CCCC
                //     color: #908C8C
                //     cursor: pointer
                //     &:last-child
                //         border: none
                //         color: #fff
                //         background: var(--color-primary)
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid var(--color-secondary)
                    color: var(--color-secondary)
                    cursor: pointer
                    background: inherit
                    height: 35px
                    display: flex
                    align-items: center
                    justify-content: center
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)
            &.scrollBody
                height: 400px
                // max-height: 400px
                overflow-y: auto
                padding: 0 28px

            .optsBtnAddItems
                padding-right: 10px
                button
                    text-align: center
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)
            &.scrollBody
                height: 400px
                // max-height: 400px
                overflow-y: auto
                padding: 0 28px

.main_DeleteCategory
    display: inline-block
    position: relative
    padding: 10px
    width: 100%
    max-width: 488px
    .iconDel
        font-size: 24px
        position: absolute
        right: 18px
        top: 18px
        color: #CDD4DB
        cursor: pointer
    .bodyDeleteCategory
        background-color: #fff
        border-radius: 15px
        padding: 10px
        i
            color: var(--color-secondary)
            font-size: 32px
        h3
            font-size: 20px
            margin: 15px 0
        p
            color: #908C8C
        button
            padding: 0.5rem
            border-radius: 10px
            cursor: pointer
            border: none
            color: #fff
            background: var(--color-secondary)
            width: 100px
.containerSpinner_center
    text-align: center
    display: flex
    justify-content: center
    .containerSpinner
        background: var(--color-secondary)
        border: none
        border-radius: 10px
        width: 100px
        color: #ffffff
        text-align: center
        justify-content: center
        display: flex
        align-items: center
