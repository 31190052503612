.containerCertificateSelected
    display: grid
    grid-template-rows: auto
    padding-top: 15px
    padding-bottom: 15px
    .imageCertificateSelected
        display: flex
        justify-content: center
        width: 500px
        height: auto
        img
            width: 100%
            height: auto

    .subtitleCertificateSelected
        font-weight: bold
        margin-top: 20px
        margin-bottom: 10px
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 14px
        text-align: center
        letter-spacing: 0.4px
        color: #000000
    .formCertificateSelected
        padding: 0px 60px
        text-align: left
        .contentInput
            display: grid
            grid-template-rows: auto
            p
                text-align: left
                font-family: Roboto
                font-style: normal
                font-weight: 500
                font-size: 12px
                line-height: 14px
                letter-spacing: 0.25px
                color: #000000

            .inputMapStyle
                display: grid
                input
                    margin-bottom: 25px
                .file_label
                    display: grid
                    grid-template-columns: max-content auto
                    font-size: 14px
                    .first_part
                        background: var(--color-secondary)
                        border-radius: 10px 0px 0px 10px
                        padding: 7px
                        color: white
                        width: 123px
                        display: flex
                        justify-content: center
                        align-items: center
                        cursor: pointer
                    input
                        display: none
                    .second_part
                        border: 1px solid var(--color-secondary)
                        box-sizing: border-box
                        border-radius: 0px 10px 10px 0px
                        width: 100%
                        align-items: center
                        display: flex
                        padding-left: 10px
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                        color: #908C8C
        .contentButtonSelectCertificate
            width: 100%
            margin-top: 20px
            button
                cursor: pointer
                width: 100%
                padding: 10px 17px
                text-transform: uppercase
                border: none
                background: var(--color-secondary)
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16)
                border-radius: 5px
                color: #fff
            .containerSpinner
                display: flex
                justify-content: center
                align-items: center
                height: 100%
                background: #CB347C
                border-radius: 5px
