.multiedu__input {
    padding: 0.4rem 0.5rem;
    width: 100%;
    padding-right: 4rem;
    border-radius: 12px;
    border: 2px solid #b7b7b7;
    font-size: 14px;
    &:focus {
        outline: none;
        border: 2px solid var(--color-primary);
    }
}
