.main_settings
    overflow-y: auto
    height: 100%
    .containerForm
        .profileImageChoose
            display: flex
            margin-bottom: 15px
            img
                width: 100px
                height: 100px
                border-radius: 50%
            .button_designs
                cursor: pointer
                background: var(--color-secondary)
                border-radius: 10px
                display: flex
                justify-content: center
                align-items: center
                color: #fff
                width: max-content
                height: fit-content
                padding-bottom: 0
                align-self: center
                padding: 0.5rem
                margin-left: 20px
                input
                    display: none
        label
            font-family: "Roboto"
            display: grid
            width: 365px
            grid-row-gap: 5px
            padding-bottom: 13px
            font-size: 15px
            &:first-child
                margin-top: 15px
            span
                font-weight: 500
                color: var(--color-dark)
                transition: color 0.3s
            input, select
                width: 100%
                padding: .5rem 1rem
                border: 1px solid #B3B3B3
                border-radius: .25rem
                height: 35px
                background-color: var(--color-light)
                color: var(--color-dark)
                transition: all 0.3s
                outline: none
                font-family: "Roboto"
                &::-ms-reveal
                    display: none
        .password-input
            position: relative

        .password-icon
            position: absolute
            right: 10px
            bottom: 0px
            top: 0px
            display: flex
            align-items: center
            cursor: pointer
            i
                font-size: 18px
                color: #888787
    .linemiddle
        width: 365px
        border: 1px solid #DDDDDD
        display: block
        margin: 24px 0 37px
    .container_settings
        .newstylespa
            font-size: 15px
            font-weight: 500
            font-family: 'Roboto'
        h4
            margin-top: 0
        .form_entitycontainer
            width: 100%
            max-width: 500px
            padding-top: 10px
            .contInAddEnt
                width: 100%
                position: relative
                .icon
                    position: absolute
                    color: white
                    font-size: 2rem
                    display: flex
                    justify-content: flex-end
                    bottom: 32px
                    right: 8px
                &.image
                    height: 170px
                    // z-index: 1

                .imgPort
                    width: 100%
                    height: calc(100% - 20px)
                    border-radius: 12px
                    cursor: pointer
                .imgPortada
                    width: 100%
                    height: calc(100% - 20px)
                    background-color: #C4C4C4
                    border-radius: 12px
                .imgPerf
                    width: 64px
                    height: 64px
                    background: var(--color-primary)
                    border-radius: 50%
                    position: absolute
                    bottom: 0
                    left: 30px
                    display: flex
                    justify-content: center
                    align-items: center
                    .iconPerf
                        position: absolute
                        color: black
                        font-size: 1rem
                        display: flex
                        justify-content: flex-end
                        bottom: 12px
                        right: 4px
                    &.active
                        background: #fff
                    div:first-child
                        display: grid
                        place-items: center
                        color: #fff
                        i
                            font-size: 24px
                p
                    margin: 0 0 5px 0
                    color: #000
                .title
                    color: var(--color-primary)
                input, select
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    font-size: 14px
                    font-family: "Roboto"
                    background: white
                textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    max-height: 100px
                    min-height: 100px
                    font-family: "Roboto"
        section
            .list_more_options
                .item_container
                    cursor: pointer
                    .item_content
                        display: flex
                        justify-content: space-between
                        background: #FEFEFE
                        border: 1px solid #F5F6F8
                        box-sizing: border-box
                        border-radius: 12px 12px 0px 0px
                        padding: 15px
                        .first
                            p
                                margin: 0
                                &:first-child
                                    font-weight: bold
                        .last
                            display: flex
                            justify-content: center
                            align-items: center
                            i
                                font-size: 28px
    .theme_container
        .headInfo
            span
                font-weight: 500
                color: var(--color-dark)
                transition: color 0.3s
                font-size: 15px
                cursor: pointer

            p
                color: var(--color-dark)
                transition: color 0.3s
                margin: 10px 0px
                font-size: 14px
        .bodyOptsApar
            display: grid
            grid-template-columns: max-content 220px
            padding: 25px 0
            .imgColors
                display: grid
                grid-template-rows: max-content 50px
                grid-row-gap: 15px
                justify-items: center
                img
                    height: 100px
                    width: auto
                    max-width: 340px
                    object-fit: contain
                .content_Colors
                    display: grid
                    grid-auto-flow: column
                    grid-column-gap: 15px
                    .circle
                        transform: rotate(30deg)
                        cursor: pointer
                        .semi-circulo
                            width: 50px
                            height: 25px
                            background-color: var(--color-dashboard)
                            border-radius: 100px 100px 0 0
                            transition: background-color 0.3s
                            &.invert
                                border-radius: 0 0 100px 100px
            .inputContent
                margin-left: 15px
                .designImgLabel
                    cursor: pointer
                    background-color: #EAEEF1
                    padding: 8px
                    color: rgba(0, 0, 0, 0.45)
                    font-weight: 500
                    font-size: 12px
                    line-height: 12px
                    text-align: center
                    letter-spacing: 1.25px
                    text-transform: uppercase
                    i
                        font-size: 15px
                        margin-right: 8px
                .inputFile
                    display: none
                .deleteImage
                    cursor: pointer
                    margin-top: 24px
                    color: #B3B3B3
                    font-size: 12px
                    letter-spacing: 1.25px
                    text-transform: uppercase
                    i
                        margin-right: 8px
        .design_advanced
            display: flex
            flex-direction: column
            width: 220px
            label
                display: flex
                justify-content: space-between
                &:last-child
                    margin-top: 15px
    .font_container
        .headInfo

            span
                font-weight: 500
                color: var(--color-dark)
                transition: color 0.3s
                font-size: 15px
                cursor: pointer
            p
                color: var(--color-dark)
                transition: color 0.3s
                margin: 10px 0px
                font-size: 14px
        .design_advanced
            display: flex
            flex-direction: column
            width: 100%
            .container_select
                span
                    font-weight: 500
                    font-size: 16px
                p
                    margin: 0
                    margin-left: 20px
                    margin-bottom: 20px
                .content_select
                    display: flex
                    flex-direction: column
                    margin-left: 20px
                    width: 300px
                    margin-top: 15px
                    label
                        margin-bottom: 5px
                        font-size: 14px
                    .design_input
                        height: 33px
                        width: 100%
                        background: #FFFFFF
                        border: 1px solid #D8CCCC
                        border-radius: 10px
                        margin-bottom: 18px
.buttonContainerProfile
    margin-top: 15px
    .buttonDesignProfile
        // height: 40px
        width: 160px
        font-size: 14px
        letter-spacing: 1.25px
        // text-transform: uppercase
        background: var(--color-secondary)
        // border-radius: 5px
        border: 1px solid var(--color-secondary)
        color: #ffffff
        padding: 0.5rem
        border-radius: 10px
        min-width: 100px
        min-height: 34.1px
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
