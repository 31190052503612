.lds-ringLogin
    display: inline-block
    position: relative
    width: 45px
    height: 45px

.lds-ringLogin div
    box-sizing: border-box
    display: block
    position: absolute
    width: 36px
    height: 36px
    margin: 8px
    border: 8px solid #ffffff
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: #ffffff transparent transparent transparent

.lds-ringLogin div:nth-child(1)
    animation-delay: -0.45s

.lds-ringLogin div:nth-child(2)
    animation-delay: -0.3s

.lds-ringLogin div:nth-child(3)
    animation-delay: -0.15s

@keyframes lds-ring
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
