.main_sideProfile
    background-color: var(--color-light)
    border-radius: 10px
    padding: 22px
    margin-right: 30px
    display: grid
    grid-template-rows: 50px
    transition: background-color 0.3s
    grid-row-gap: 10px
    overflow-y: auto
    .headSideProfile
        display: grid
        grid-template-columns: 50px max-content max-content auto
        grid-column-gap: 15px
        align-items: center
        font-size: 17px
        font-weight: 500
        color: var(--color-dark)
        text-transform: capitalize
        transition: color 0.3s
        i
            font-size: 50px
            color: #B3B3B3
        img
            width: 50px
            height: 50px
            border-radius: 50%
        span:last-child
            color: var(--color-secondary)
            transition: color 0.3s
    .bodySideProfile
        display: grid
        grid-template-columns: 200px auto
        grid-column-gap: 10px
        .optRouts
            display: grid
            grid-auto-flow: row
            grid-auto-rows: 35px
            grid-row-gap: 5px
            align-items: center
            font-size: 15px
            margin: 0
            padding: 0
            list-style: none
            color: #B3B3B3
            .link_sidebar
                margin: 0
                padding: 0 30px
                &.active
                    font-weight: 500
                    color: var(--color-dark)
                    transition: color 0.3s
