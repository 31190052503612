@import './auth/login'
@import './auth/contentLogin'

@import './containers/sidebar'
@import './containers/sidebarProfile'

@import './containers/dashboard/itemUser/itemUser.sass'
@import './containers/dashboard/usuariosPage.sass'
@import './containers/dashboard/entidadesPage.sass'
@import './containers/adminb2b/coursesB2BPage.sass'
@import './containers/adminb2b/moduleCourses.sass'
@import './containers/adminb2b/usuariosB2BPage.sass'
@import './containers/adminb2b/homeB2bPage.sass'
@import './containers/adminb2b/repositoryPage.sass'
@import './containers/adminb2b/settingPage.sass'
@import './containers/dashboard/permissionsPage'
@import './containers/counter/inscribedUserPage'

@import './modals/modalCreateUsers'
@import './modals/containerModal'
@import './modals/containerModalAllPage'
@import './modals/crudCourses'
@import './modals/crudUsers'
@import './modals/crudStudents'
@import './modals/crudEntity'
@import './modals/crudCategory'
@import './modals/crudFiles'
@import './modals/crudStudentCounter'
@import './modals/crudUserEnrollment'
@import './modals/generateCertificateModal'
@import './modals/detailVoucherModal'
@import './modals/sendEmailModal'
@import './modals/crudEvaluation'
@import './modals/modalSelectCertificate'

@import './views/certificateView'

@import './forms/formLinkInvitation'
@import './forms/formUserB2b'

@import './general/detailsUser'
@import './cards/CardEntity'
@import './containers/dashboard/itemPermissions/itemPermissions'


.scroll
    overflow-y: auto
    scroll-behavior: smooth
    &::-webkit-scrollbar
        -webkit-appearance: none
    &::-webkit-scrollbar:vertical
        width: 20px
    &::-webkit-scrollbar:horizontal
        height: 10px
    &::-webkit-scrollbar-track
        border-radius: 10px
        -webkit-box-shadow: none
    &::-webkit-scrollbar-thumb
        background-color: #50505038
        border-radius: 0
        border-left: 10px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        &:hover
            background-color: #50505094
        &:active
            background-color: var(--color-secondary)

@media screen and (max-width: 425px)
    .scroll
        &::-webkit-scrollbar:vertical
            width: 18px