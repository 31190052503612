.container_EntidadesPage
    display: grid
    grid-template-rows: 50px calc(100% - 50px)
    width: 100%
    overflow-y: auto
    .headUserPag
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
        div
            width: 120px
            display: flex
            align-items: center
            justify-content: space-between
    .bodyUserPag
        overflow-y: auto
        display: grid
        grid-template-rows: max-content auto
        grid-row-gap: 10px
        padding-right: 30px
        .buttonAddContainer
            text-align: left
            display: flex
            justify-content: flex-end
            .designButtonAdd
                background: #FFFFFF
                border: 1px solid rgba(0, 0, 0, 0.2)
                box-sizing: border-box
                border-radius: 5px
                width: 185px
                display: flex
                justify-content: space-between
                padding: 8px 6px
                align-items: center
                &:hover
                    background: #d1d1d1
                    border-radius: 5px
        .cardEntity_container
            width: 100%
            background: #FFFFFF
            border-radius: 24px
            padding: 27px
            display: grid
            grid-template-rows: max-content auto
            grid-gap: 25px
            .cardEntity_header
                h3
                    margin: 0
                    font-style: normal
                    font-weight: 500
                    font-size: 20px
                    line-height: 21px
                    color: #0A213D
            .cardEntity_content
                .cardEntity_body
                    display: grid
                    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr))
                    grid-column-gap: 20px
                    grid-row-gap: 50px
                    justify-content: center
                    width: 100%
                    background-color: #fff
                    padding: 20px
                    .spinnerEntity
                        display: flex
                        justify-content: center
                        align-items: center
                        text-align: center
