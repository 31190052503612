.main_Status
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    background: #fff
    .contError
        display: grid
        grid-template-columns: repeat(2, calc(50% - 10px))
        padding: 1rem
        align-items: center
        justify-content: center
        grid-gap: 20px
        &.notice
            grid-template-columns: none
        img
            height: 100%
            max-height: 400px
            width: 100%
        .contMessg
            display: grid
            justify-items: center
            height: 100%
            grid-template-rows: repeat(3, max-content)
            align-content: center
            grid-row-gap: 15px
            div
                &:first-child
                    font-family: system-ui
                    font-style: normal
                    font-weight: bold
                    font-size: 72px
                &:nth-child(2)
                    font-family: Roboto
                    font-size: 24px
                    text-align: center
            button
                border-radius: 4px
                border: none
                background: #828282
                color: #fff
                padding: 0.5rem 1rem
                font-size: 16px

@media screen and (max-width: 768px)
    .main_Status
        .contError
            img
                max-height: 300px
            .contMessg
                div
                    &:first-child
                        font-size: 52px
                    &:nth-child(2)
                        font-size: 19.2px

@media screen and (max-width: 425px)
    .main_Status
        .contError
            &.notice
                .contMessg
                    div
                        &:first-child
                            font-size: 40px

@media screen and (max-height: 425px)
    .main_Status
        .contError
            &.notice
                img
                    max-height: 111px
                .contMessg
                    grid-row-gap: 10px
                    div
                        &:first-child
                            font-size: 40px
            img
                max-height: 240px
            .contMessg
                grid-row-gap: 10px
