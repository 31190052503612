.formLinkInvitation_container
    padding: 30px
    form
        .container_input
            div
                margin-bottom: 12px
            input
                width: 100%
                height: 33px
                background: #FFFFFF
                border: 1px solid #D8CCCC
                border-radius: 10px
        .text_second_form
            margin: 12px 0px
        .container_input_two
            display: flex
            justify-content: space-between
            input
                width: 80%
                height: 33px
                background: #FFFFFF
                border: 1px solid #D8CCCC
                border-radius: 10px
            button
                background: #1FC1C0
                border-radius: 5px
                color: white
                padding: 8px
                width: 15%
                border: none
    hr
        margin: 20px 0px
    .button_add_user
        button
            background: #1FC1C0
            border-radius: 5px
            border: none
            color: #ffffff
            padding: 8px
            width: 30%
    .button_cancel
        color: #1FC1C0
        border: none
        text-align: right
        font-weight: 500
        font-size: 14px
