.container_form_create_user
    .infoDetailsCrU
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 72px
        overflow-x: hidden
        overflow-y: auto
        .firstForm
            top: 0
            width: 100%
            bottom: 0
            position: absolute
            left: 0
            transition: left 0.3s
            padding: 25px 40px
            &.active
                left: -40vw
            .container_input_form
                display: flex
                justify-content: center
                align-items: center
                span
                    color: #1FC1C0
                    margin-left: 4px
                .designFormInput
                    margin-right: 4px
                    i
                        border-radius: 50%
                        font-size: 30px
                        background-color: #1FC1C0
                        color: #ffffff
                        padding: 25px
                .hideInputForm
                    display: none
            .container_input
                .text_form_create_user
                    margin-bottom: 8px
                .design_input
                    height: 33px
                    width: 100%
                    background: #FFFFFF
                    border: 1px solid #D8CCCC
                    border-radius: 10px
                    margin-bottom: 18px
                .cv_input
                    display: none
                .designFormUpload
                    color: #1FC1C0
                    background: #FFFFFF
                    border: 1px solid #1FC1C0
                    border-radius: 10px
                    padding: 5px 20px
            .container_row
                display: flex
                justify-content: space-between
                .container_input_left
                    width: 48%
                    .text_form_create_user
                        margin-bottom: 8px
                    .design_input
                        height: 33px
                        width: 100%
                        background: #FFFFFF
                        border: 1px solid #D8CCCC
                        border-radius: 10px
                        margin-bottom: 18px
                .container_input_right
                    width: 48%
                    .text_form_create_user
                        margin-bottom: 8px
                    .design_input
                        height: 33px
                        width: 100%
                        background: #FFFFFF
                        border: 1px solid #D8CCCC
                        border-radius: 10px
                        margin-bottom: 18px
        .secondForm
            top: 0
            width: 100%
            bottom: 0
            position: absolute
            right: -40vw
            transition: right 0.3s
            padding: 25px 40px
            &.active
                right: 0
            .container_input
                .text_form_create_user
                    margin-bottom: 8px
                .design_input
                    height: 33px
                    width: 100%
                    background: #FFFFFF
                    border: 1px solid #D8CCCC
                    border-radius: 10px
                    margin-bottom: 18px
                .cv_input
                    display: none
                .designFormUpload
                    color: #1FC1C0
                    background: #FFFFFF
                    border: 1px solid #1FC1C0
                    border-radius: 10px
                    padding: 5px 20px
            .container_row
                display: flex
                justify-content: space-between
                .container_input_left
                    width: 48%
                    .text_form_create_user
                        margin-bottom: 8px
                    .design_input
                        height: 33px
                        width: 100%
                        background: #FFFFFF
                        border: 1px solid #D8CCCC
                        border-radius: 10px
                        margin-bottom: 18px
                .container_input_right
                    width: 48%
                    .text_form_create_user
                        margin-bottom: 8px
                    .design_input
                        height: 33px
                        width: 100%
                        background: #FFFFFF
                        border: 1px solid #D8CCCC
                        border-radius: 10px
                        margin-bottom: 18px
    .btnDetCrU
        position: absolute
        bottom: 10px
        right: 0
        left: 0
        padding: 0 40px
        .container_pagination
            display: flex
            justify-content: center
            .content_left
                width: 40px
                height: 6px
                margin-right: 5px
                border-radius: 15px
                background-color: #C4C4C4
                &.active
                    background-color: #1FC1C0
            .content_right
                width: 40px
                height: 6px
                border-radius: 15px
                background-color: #C4C4C4
                &.active
                    background-color: #1FC1C0
        .container_button_userB2B
            display: flex
            justify-content: space-between
            margin-top: 20px
            align-items: center
            // .container_cancel_button
            .container_next_button
                button
                    color: #ffffff
                    border-radius: 15px
                    border: none
                    background-color: #1FC1C0
                    text-align: right
                    font-weight: 500
                    font-size: 14px
                    padding: 10px
                    padding-left: 20px
                    padding-right: 20px
                .divButton
                    color: #ffffff
                    border-radius: 15px
                    border: none
                    background-color: #1FC1C0
                    text-align: right
                    font-weight: 500
                    font-size: 14px
                    padding: 10px
                    padding-left: 20px
                    padding-right: 20px
    form
        .container_input_form
            display: flex
            justify-content: center
            align-items: center
            span
                color: #1FC1C0
                margin-left: 4px
            .designFormInput
                margin-right: 4px
                i
                    border-radius: 50%
                    font-size: 30px
                    background-color: #1FC1C0
                    color: #ffffff
                    padding: 25px
            .hideInputForm
                display: none
        .container_input
            .text_form_create_user
                margin-bottom: 8px
            .design_input
                height: 33px
                width: 100%
                background: #FFFFFF
                border: 1px solid #D8CCCC
                border-radius: 10px
                margin-bottom: 18px
            .cv_input
                display: none
            .designFormUpload
                color: #1FC1C0
                background: #FFFFFF
                border: 1px solid #1FC1C0
                border-radius: 10px
                padding: 5px 20px
        .container_row
            display: flex
            justify-content: space-between
            .container_input_left
                width: 48%
                .selectOptionUser
                    position: relative
                    width: 100%
                    height: 29px
                    background: #FFFFFF
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)
                    border-radius: 45px
                    display: flex
                    align-items: center
                    justify-content: space-around
                .text_form_create_user
                    margin-bottom: 8px
                .design_input
                    height: 33px
                    width: 100%
                    background: #FFFFFF
                    border: 1px solid #D8CCCC
                    border-radius: 10px
                    margin-bottom: 18px
            .container_input_right
                width: 48%
                .text_form_create_user
                    margin-bottom: 8px
                .design_input
                    height: 33px
                    width: 100%
                    background: #FFFFFF
                    border: 1px solid #D8CCCC
                    border-radius: 10px
                    margin-bottom: 18px
