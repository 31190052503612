.list_question
    // padding-bottom: 70px
    margin-right: 10px
    height: calc( 100vh - 415px )
    padding-bottom: 20px
    .itemQuestion
        border: 1px solid #cdd4db
        border-radius: 16px
        margin-bottom: 15px
        background: #fff
        .title_question
            padding: 15px 20px
            border-bottom: 1px solid #cdd4db
            position: relative
            span
                position: absolute
                right: 66px
                color: #000
        .description_question
            padding: 12px 20px
            border-bottom: 1px solid #cdd4db
            color: #000
        .type_question
            padding: 12px 20px
            border-bottom: 1px solid #cdd4db
            color: #000
        .alternatives_question
            padding: 12px 20px
        .form_question
            padding: 20px 20px 0
            min-height: 450px
            .container_options
                margin: 0 0 18px
                label
                    font-size: 13px
                .select
                    align-items: center
                    label, select, input
                        width: 50%
                        margin: 0
                        font-size: 13px
                    select
                        height: 30px
                &.title
                    align-items: center
                    label, input
                        width: 100px
                        margin: 0
                        font-size: 13px
                    input
                        flex: auto
                        height: 30px
                .alternatives_content
                    .alternatives_message
                        h4
                            margin: 10px 0
.menu_container_courses
    position: relative
    // cursor: pointer
    > i
        padding: 5px
        cursor: pointer
    .optSelectOption
        position: absolute
        right: 0px
        top: -100vh
        width: 180px
        transform: scaleY(0)
        transform-origin: center top
        color: #000
        width: 150px
        font-size: 14px
        z-index: 1
        align-items: flex-start
        background: #FFFFFF
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
        border-radius: 5px
        &.active
            top: 80px
            transition: transform .3s
            transform: scale(1)
            z-index: 3
        .onlyOpt
            color: #000000
            cursor: pointer
            width: 150px
            padding: 7px
            display: grid
            grid-template-columns: auto
            align-items: center
            grid-column-gap: 10px
            i
                font-size: 24px
            &:first-child
                margin-top: 8px
            &:last-child
                margin-bottom: 8px
            &:hover
                background: #B3B3B3
