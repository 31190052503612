.containerEvaluations
    display: grid
    grid-template-columns: calc(268px + 2rem) auto
    grid-column-gap: 0
    height: 100%
    font-size: 16px
    border-radius: 16px
    background-color: #fff
    .btnBankQuestions
        position: absolute
        background: var(--color-secondary)
        color: var(--color-light)
        padding: 10px 20px
        border-radius: 10px
        top: 120px
        right: 30px
        z-index: 1
    .containerLeftEvaluations
        display: grid
        grid-auto-flow: row
        grid-auto-rows: max-content
        grid-row-gap: 15px
        font-family: Roboto
        padding: 1rem
        border-right: 1px solid #E4E4E4
        .headerLeftEvaluations
            height: 120px
            padding: 30px
            .iconBoxs
                margin-bottom: 30px
                display: flex
                width: 26.7px
                height: 26.7px
                flex-wrap: wrap
                justify-content: space-between
                align-content: space-between
                div
                    width: 11.3px
                    height: 11.3px
                    border-radius: 3px
                    background: var(--color-secondary)

            .titlePage
                font-family: Roboto
                font-weight: 900
                font-size: 20px
                display: flex
                align-items: center
                justify-content: space-between
                i
                    background: #F5F6F8
                    padding: 6px
                    width: 24px
                    height: 24px
                    color: #CDD4DB
                    font-size: 14px
                    border-radius: 5px
                    cursor: pointer

    .containerRightEvaluations
        padding: 2rem 2rem 0 2rem
        position: relative
        overflow-x: auto
        .noOptsSelect
            display: grid
            justify-items: center
            width: 100%
            height: 100%
            align-content: center
            color: #7A8D9F
            font-family: sans-serif
            grid-row-gap: 5px
            text-align: center
            font-size: 14px
            font-weight: bold
            div:nth-child(2)
                font-size: 20px
                font-weight: 900
        .contFormum
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            align-items: center
            color: #908C8C
            .headerContentEvaluation
                width: 50%
                height: 106px
                .icon
                    font-size: 32px
                    color: #27CD41
                .title
                    margin-top: 25px
                    margin-bottom: 25px
                    color: #27CD41
                    font-size: 20px
                // .description
            .contBtns
                padding-top: 15px
                display: grid
                grid-auto-flow: column
                grid-auto-columns: max-content
                justify-content: right
                grid-column-gap: 10px
                height: 32px
                font-size: 12px
                width: 50%
                .btnOpts
                    padding: 0.5rem 1rem
                    border-radius: 10px
                    border: 1px solid var(--color-secondary)
                    color: var(--color-secondary)
                    cursor: pointer
                    &.modify
                        color: #fff
                        background-color: var(--color-secondary)
                .newBtn
                    position: relative
                    .btnMody
                        display: flex
                        height: 100%
                        width: 100px
                        color: #fff
                        background-color: var(--color-secondary)
                        border-radius: 10px
                        cursor: pointer
                        border: none
                        height: 32px
                        align-items: center
                        justify-content: center
                        span
                            display: flex
                            align-items: center
                            padding: 0.5rem 0.8rem
                            &:last-child
                                border-top-right-radius: 10px
                                border-bottom-right-radius: 10px
                    .moreOpts
                        background: #fff
                        border: 1px solid #D3DCE6
                        border-radius: 6px
                        display: grid
                        grid-row-gap: 10px
                        padding: 12px
                        position: absolute
                        right: 0px
                        top: 34px
                        width: 100%
                        div
                            padding: 7px
                            cursor: pointer
                            &:hover
                                background-color: #EFF2F7
                .cogbtn
                    display: flex
                    align-items: center
                    font-size: 20px
                    color: #AEAEAF
                    cursor: pointer
                    // &.alerta

            .separator
                border: 1px solid #F2F2F2
                width: 100%
                margin-top: 15px
                margin-bottom: 15px
            .bodyContentEvaluation
                width: 100%
                height: calc( 100vh - 301px)
                overflow-x: auto
                // .questions
                .optionsAdd
                    z-index: 1
                    position: absolute
                    bottom: 30px
                    left: calc( 50% - 235px )
                    display: flex
                    justify-content: space-between
                    width: 470px
                    // .btn100
                    //     width: 47%
            .titleFrm
                font-size: 20px
                color: var(--color-secondary)
            .itemForum
                width: 100%
                p
                    margin: 0 0 5px 0
                    color: #000
                input, textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    font-family: 'Roboto'
                input
                    max-width: 600px
                textarea
                    max-width: 800px
                    height: 70px
        .contForoItem
            display: grid
            grid-row-gap: 10px
            font-family: 'Roboto'
            .titleForo
                font-size: 20px
                font-weight: 600
                color: var(--color-secondary)
        .contAllAnswers
            display: grid
            grid-row-gap: 10px
            padding-bottom: 10px
            .contItemAnswer
                font-family: 'Roboto'
                display: grid
                grid-row-gap: 10px
        .contMoreOpts
            background: #fff
            padding: 1rem
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25)
            position: fixed
            top: 79px
            bottom: 24px
            right: -100vw
            width: 382px
            display: grid
            grid-template-rows: max-content auto max-content
            grid-row-gap: 20px
            transition: right 0.5s
            z-index: 1
            &.active
                right: 32px
            .titmoreops
                display: grid
                grid-template-columns: auto max-content
                font-family: Roboto
                font-weight: 900
                font-size: 20px
                span:last-child
                    cursor: pointer
            .bodymoreops
                width: 100%
                font-size: 14px
                display: grid
                grid-auto-rows: max-content
                grid-row-gap: 15px
                .contTime
                    display: grid
                    grid-template-columns: repeat(2, calc(50% - 5px))
                    grid-column-gap: 10px
                    .itemTime
                        width: 100%
                        p
                            margin: 0 0 5px 0
                            color: #000
                            text-align: left
                        input
                            border: 1px solid #D8CCCC
                            box-sizing: border-box
                            border-radius: 10px
                            outline: navajowhite
                            padding: 0.5rem
                            width: 100%
                .itemselectSwi
                    display: grid
                    grid-template-columns: auto 47px
                    grid-column-gap: 10px
                    align-items: center
                    .switch
                        background: #adaeaf
                        border-radius: 1000px
                        border: none
                        position: relative
                        cursor: pointer
                        display: flex
                        outline: none
                        height: 20px
                        &.active
                            background: var(--color-secondary)
                            &::after
                                left: unset
                                right: 0
                        &::after
                            content: ""
                            display: block
                            width: 20px
                            height: 20px
                            position: absolute
                            background: #F1F1F1
                            top: 0
                            left: 0
                            right: unset
                            border-radius: 100px
                            transition: .3s ease all
                            box-shadow: 0px 0px 2px 2px rgba(0,0,0,.2)
            .btnsmoreOps
                display: grid
                grid-auto-flow: column
                grid-auto-columns: 100px
                justify-content: right
                grid-column-gap: 10px
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    height: 32px
                    display: flex
                    justify-content: center
                    align-items: center
                    &:last-child
                        border: none
                        color: #fff
                        background-color: var(--color-secondary)

.container_options
    display: flex
    width: 100%
    justify-content: space-between
    flex-wrap: wrap
    text-align: left
    .select
        width: 45%
        display: flex
        flex-wrap: wrap
        &>select
            width: 100%
            height: 33px
            background: #FFFFFF
            border: 1px solid #D8CCCC
            box-sizing: border-box
            border-radius: 10px
            margin-top: 10px
            padding-left: 15px
            outline: none
        &.time
            justify-content: space-between
            input
                width: 40%
            span
                font-size: 22px
    .input_question
        width: 100%
        height: 33px
        background: #FFFFFF
        border: 1px solid #D8CCCC
        box-sizing: border-box
        border-radius: 10px
        padding-left: 15px
        outline: none
        font-size: 16px
    label
        width: 100%
        margin-bottom: 5px
        font-style: normal
        font-size: 14px
        line-height: 14px
        color: var(--color-dark)
    .alternatives_content
        display: flex
        flex-wrap: wrap
        width: 100%
        h5
            margin: 0
        .alternatives_item
            width: 100%
            display: flex
            margin: 5px 0
            &>div
                width: 40%
                display: flex
                margin-right: 20px
                height: 33px
                align-items: center
                &>input
                    margin-right: 10px
                &>button
                    background: var(--color-secondary)
                    border: none
                    width: 30px
                    height: 25px
                    color: #fff
                    border-radius: 5px
                &.choose
                    cursor: pointer
                    input, span
                        pointer-events: none
            p
                margin: 0
        .alternatives_message
            margin: 5px 0
            width: 100%
        .alternatives_buttons
            margin: 5px 0
            width: 100%
            text-align: center
            button
                color: #ffffff
                cursor: pointer
                height: 30px
                padding: 0 20px
                background: var(--color-secondary)
                border: 1px solid #D8CCCC
                box-sizing: border-box
                border-radius: 10px
                outline: none
                margin: 0 50px
    &.button_options
        justify-content: center
        button
            color: #ffffff
            cursor: pointer
            height: 40px
            padding: 0 20px
            background: var(--color-secondary)
            border: 1px solid #D8CCCC
            box-sizing: border-box
            border-radius: 10px
            outline: none
            margin: 0 50px
    .content_config
        width: 350px
        min-height: 565px
        background: var(--color-light)
        box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04)
        border-radius: 15px
        padding: 20px
        .title
            margin: 0 0 20px
            text-align: center
            color: var(--color-secondary)
    .content_setting
        width: 100%
        border: 1px solid #D8CCCC
        border-radius: 10px
        padding: 15px 15px 5px
        .setting
            display: flex
            align-items: center
            margin-bottom: 10px
            cursor: pointer
            i
                color: var(--color-dark)
                font-size: 20px
                cursor: pointer
            label
                margin-left: 15px
                font-size: 15px
                margin-bottom: 0
                font-weight: normal
                pointer-events: none
    .option_quantity
        display: flex
        width: 100%
        align-items: center
        margin-bottom: 10px
        label
            width: 50px
            margin-bottom: 0
            &.label_quantity
                width: 140px
        .input_question
            width: 80px
            margin-right: 10px
        .btn_Page_Evaluations
            width: 100%
            height: 35px

//generales
.search100
    position: relative
    height: 35px
    display: flex
    width: 100%
    input
        width: 100%
        padding: .5rem 1rem .5rem 2.5rem
        border: none
        background: #fff
        border-radius: 20px
        height: 35px
        font-size: 16px
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25)
    i
        position: absolute
        font-size: 18px
        top: 8px
        left: 12px
        color: var(--color-secondary)
.btn100
    // width: 100%
    // padding: 0.5rem 1rem
    // background-color: var(--color-secondary)
    // color: #fff
    // display: flex
    // justify-content: space-between
    // border-radius: 13px
    // height: 40px
    // align-items: center
    // cursor: pointer
    padding: 0.5rem 1rem
    background-color: var(--color-secondary)
    color: #fff
    display: flex
    gap: .5rem
    justify-content: center
    border-radius: 10px
    max-height: 40px
    align-items: center
    cursor: pointer
    &:hover
        opacity: .8
    span
        width: max-content
