.main_AddCourse
    width: 100%
    max-width: 488px
    padding: 10px
    display: inline-block
    .headAddCour
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #ffffff
            cursor: pointer
    .bodyAddCour
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px 0px
        display: grid
        grid-row-gap: 20px
        .sepScroll
            height: auto
            max-height: 300px
            overflow-y: auto
            padding: 0px 15px
            padding-bottom: 10px
            display: flex
            flex-direction: column
            row-gap: 15px
        .catAddCour
            display: grid
            grid-template-columns: repeat(3, 1fr)
            grid-column-gap: 10px
            .itemCat
                border: 1px solid #0E3873
                border-radius: 10px
                color: var(--color-secondary)
                height: 28px
                display: flex
                justify-content: center
                align-items: center
                font-size: 14px
                cursor: pointer
                &.active
                    color: #fff
                    background: var(--color-secondary)
        .contPhotoCourse
            display: flex
            justify-content: center
            .contImgCour
                display: flex
                width: max-content
                gap: 18px
                align-items: center
                .imgDeforView
                    width: 63px
                    height: 63px
                    border-radius: 50%
                    background: var(--color-secondary)
                    display: flex
                    align-items: center
                    justify-content: center
                    i
                        color: #fff
                        font-size: 22px
                label
                    cursor: pointer
                    color: var(--color-secondary)
                    font-size: 14px
                    input
                        display: none
        .contFormCour
            display: grid
            grid-auto-flow: row
            grid-row-gap: 10px
            text-align: left
            font-size: 14px
            color: #908C8C
            .sepContAddCour
                display: grid
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 16px
            .contInAddCour
                width: 100%
                position: relative

                .half_content_file
                    display: flex
                    .text_switch
                        align-self: center
                        margin: 0
                        margin-right: 10px
                        color: #000
                    .switch
                        background: #F2F2F2
                        border-radius: 1000px
                        border: none
                        position: relative
                        cursor: pointer
                        display: flex
                        outline: none
                        &.active
                            background: var(--color-secondary)
                            &::after
                                left: unset
                                right: 0
                        &::after
                            content: ""
                            display: block
                            width: 25px
                            height: 25px
                            position: absolute
                            background: #F1F1F1
                            top: 0
                            left: 0
                            right: unset
                            border-radius: 100px
                            transition: .3s ease all
                            box-shadow: 0px 0px 2px 2px rgba(0,0,0,.2)
                        span
                            width: 23px
                            height: 23px
                            line-height: 23px
                            display: block
                            background: none
                            color: #fff
                .file_label
                    display: grid
                    grid-template-columns: max-content auto
                    .first_part
                        background: var(--color-secondary)
                        border-radius: 10px 0px 0px 10px
                        padding: 7px
                        color: white
                        width: 123px
                        display: flex
                        justify-content: center
                        align-items: center
                    .input_design
                        display: none
                    .second_part
                        border: 1px solid var(--color-secondary)
                        box-sizing: border-box
                        border-radius: 0px 10px 10px 0px
                        width: 100%
                        align-items: center
                        display: flex
                        padding-left: 10px
                        // width: 100%
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                p
                    margin: 0 0 5px 0
                input, select
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                textarea
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: navajowhite
                    padding: 0.5rem
                    width: 100%
                    max-height: 100px
                    min-height: 100px

                select.half
                    width: calc(50% - 8px)
            .optsBtnAdd
                display: grid
                grid-template-columns: 100px 100px
                justify-content: right
                grid-column-gap: 10px
                padding: 0 30px
                .containerSpinner
                    background: var(--color-secondary)
                    border: none
                    border-radius: 10px
                    width: 100%
                    // padding: 0.5rem
                    color: #ffffff
                    font-weight: 500
                    font-size: 14px
                    text-align: center
                    justify-content: center
                    display: flex
                    align-items: center
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid #D8CCCC
                    color: #908C8C
                    cursor: pointer
                    border: 1px solid var(--color-secondary)
                    color: var(--color-secondary)
                    background: inherit
                    height: 35px
                    display: flex
                    justify-content: center
                    align-items: center
                    &:last-child
                        border: none
                        color: #fff
                        background: var(--color-secondary)

.main_Delete
    display: inline-block
    position: relative
    padding: 10px
    width: 100%
    max-width: 488px
    .headModal
        background: var(--color-secondary)
        color: #fff
        display: flex
        padding: 16px
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        position: relative
        justify-content: center
        h3
            margin: 0
            font-size: 20px
        i
            font-size: 24px
            position: absolute
            right: 18px
            top: 18px
            color: #fff
            cursor: pointer
    .bodyModals
        background: #fff
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        padding: 22px
        display: grid
        grid-row-gap: 15px
        display: grid
        grid-auto-flow: row
        grid-row-gap: 15px
        font-size: 14px
        >p
            &:first-child
                font-size: 16px
                font-weight: 500
        >div
            &:first-child
                display: grid
                grid-auto-flow: row
                grid-row-gap: 15px
                text-align: left
                font-size: 14px
                height: auto
                max-height: 480px
                overflow-y: auto
                &::-webkit-scrollbar
                    -webkit-appearance: none
                &::-webkit-scrollbar:vertical
                    width: 20px
                &::-webkit-scrollbar:horizontal
                    height: 10px
                &::-webkit-scrollbar-track
                    border-radius: 10px
                    box-shadow: none
                &::-webkit-scrollbar-thumb
                    background-color: #50505038
                    border-radius: 0
                    border-left: 10px solid rgba(0, 0, 0, 0)
                    background-clip: padding-box
                    &:hover
                        background-color: #50505094
                    &:active
                        background-color: var(--color-secondary)
                >div
                    display: grid
                    grid-template-columns: repeat(2, 1fr)
                    grid-gap: 16px
                label
                    width: 100%
                    p
                        margin-bottom: 5px
                        font-weight: 700
                    input, select, textarea
                        border: 1px solid #D8CCCC
                        box-sizing: border-box
                        border-radius: 10px
                        padding: 0.5rem
                        width: 100%
                    textarea
                        height: 80px
                        resize: none
            &:last-child
                display: grid
                grid-template-columns: 100px 100px
                justify-content: center
                grid-column-gap: 10px
                button
                    padding: 0.5rem
                    border-radius: 10px
                    border: 1px solid var(--color-secondary)
                    color: var(--color-secondary)
                    cursor: pointer
                    background-color: #fff
                    &:last-child
                        border: none
                        color: #fff
                        background-color: var(--color-secondary)
