.multiedu {
    &__table {
        &--head {
            display: flex;
            gap: 0.5rem;
        }
        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0;
            border-bottom: 1px solid #e6e6e6;
        }
        &--title {
            font-size: 1.2rem;
            font-weight: bold;
            color: #4a4a4a;
        }
        &--filter {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            height: 100%;
        }
        &--input {
            padding: 0.4rem 0.5rem;
            width: 100%;
            padding-right: 4rem;
            border-radius: 12px;
            border: 1px solid #b7b7b7;
            font-size: 14px;
        }
        &--row {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
        }
    }
}
