.lds-ring
    display: flex
    position: relative
    width: 32.5px
    justify-content: center
    height: 32.5px
    align-items: center
    & div
        box-sizing: border-box
        display: block
        position: absolute
        width: 30px
        height: 30px
        margin: 8px
        border: 8px solid var(--color-secondary)
        border-radius: 50%
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
        border-color: var(--color-secondary) transparent transparent transparent
    & div:nth-child(1)
        animation-delay: -0.45s
    & div:nth-child(2)
        animation-delay: -0.3s
    & div:nth-child(3)
        animation-delay: -0.15s

@keyframes lds-ring
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
