.container_PermisosPage
    display: grid
    grid-template-rows: 50px calc(100% - 50px)
    width: 100%
    overflow-y: auto
    .headUserPag
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
        div
            width: 120px
            display: flex
            align-items: center
            justify-content: space-between
    .bodyUserPag
        overflow-y: auto
        overflow-x: hidden
        display: grid
        grid-auto-flow: row
        grid-auto-rows: max-content
        grid-row-gap: 10px
        padding-right: 30px
        margin-top: 20px
        .cardPermisos_container
            background: #FFFFFF
            border-radius: 24px
            padding: 27px
            font-size: 14px
            .cardPermisos_content
                .cardPermisos_header
                    .headerContainer_Permisos
                        display: flex
                        background: rgba(0, 0, 0, 0.05)
                        border-radius: 24px
                        justify-content: space-evenly
                        align-items: center
                        width: 100%
                        margin-bottom: 24px
                        .itemHeader
                            width: 50%
                            cursor: pointer
                            h5
                                text-align: center
                                padding-top: 10px
                                padding-bottom: 10px
                                margin: 0
                        .itemHeader_active
                            background: #0E3873
                            border-radius: 23px
                            width: 50%
                            cursor: pointer
                            color: #ffffff
                            h5
                                text-align: center
                                padding-top: 10px
                                padding-bottom: 10px
                                margin: 0
                    span
                        font-style: normal
                        font-weight: normal
                        font-size: 12px
                        line-height: 14px
                        letter-spacing: 0.07em
                        color: #5A5A5A
                    .search_box_permisos
                        background: #F8F9FA
                        border: 1px solid #FFFFFF
                        box-sizing: border-box
                        border-radius: 16px
                        display: flex
                        height: 44px
                        align-items: center
                        padding: 0px 20px
                        justify-content: space-between
                        margin-top: 25px
                        margin-bottom: 25px
                        .text_search_permisos
                            font-style: normal
                            font-weight: 500
                            font-size: 13px
                            line-height: 15px
                            text-align: center
                            letter-spacing: 0.15px
                            color: #D6D6D6
                        .icons_search_permisos
                            color: #D6D6D6
                            .fa-filter
                                margin-right: 15px
                .cardPermisos_body
                    display: flex
                    justify-content: space-between
                    flex-direction: column
                    .title_permissions_header
                        display: flex
                        justify-content: space-between
                        font-weight: bold
                        margin-bottom: 20px
                    .title_permissions_account_header
                        display: grid
                        grid-template-columns: 50% repeat(5, auto)
                        .role_admins
                            text-align: center
                    .button_design_permissions
                        padding: 0.5rem
                        border-radius: 10px
                        color: #908C8C
                        cursor: pointer
                        border: none
                        color: #fff
                        background: #0E3873
