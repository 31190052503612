.btn_Page_Evaluations
    cursor: pointer
    min-width: 130px
    height: 32px
    background: var(--color-secondary)
    border: 1px solid #CCCCCC
    font-size: 12px
    color: #ffffff
    outline: none
    display: inline-block
    padding: 5px 10px
    border-radius: 5px
    text-align: center
.pointernone
    pointer-events: none
.courses_container_page
    .headUserPag
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 30px
        margin-bottom: 15px
        h3
            margin: 0px
            font-size: 28px
            color: var(--color-secondary)
    &.Evaluations
        display: grid
        grid-template-rows: 50px calc(100% - 50px)
        width: 100%
        overflow-y: auto
        .inpSearch
            max-width: 100% !important
        .course_content_page

            .left_categories_container
                height: calc( 100vh - 150px )
            .rigth_courses_container
                height: calc( 100vh - 150px )

.evaluation_body
    display: grid
    grid-template-columns: 281px auto
    width: 100%
    grid-column-gap: 15px
    height: 100%
    &.with_max
        width: 100% !important
        grid-template-columns: none
        div
            width: 100% !important
    .left_content
        background-color: #ffffff
        padding: 18px 18px 0 18px
        // width: max-content !important
        overflow-x: auto
    .rigth_content
        height: 100%
        width: 100%
        overflow-y: auto
        display: grid
        grid-template-rows: max-content auto
        grid-row-gap: 15px
        .title_right_courses
            border-top-right-radius: 0 !important
.listEvaluations
    display: grid
    grid-auto-flow: row
    grid-auto-rows: max-content
    grid-row-gap: 10px
    width: 100%
    overflow-x: auto
    height: calc( 100vh - 385px )
    &.category
        height: calc( 100vh - 230px )
    &.courses
        height: calc( 100vh - 268px )
    &.modules
        height: calc( 100vh - 365px )

    .itemaForum
        border-radius: 12px
        height: 64px
        padding: 0 10px
        display: grid
        align-items: center
        grid-template-columns: calc( 100% - 20px) 20px
        width: 100%
        &.colum2
            grid-template-columns: auto 150px
            border-style: groove
            .btn
                background: var(--color-secondary)
                color: #ffffff
                text-align: center
                padding: 5px
                border-radius: 10px
                cursor: pointer
        &.pointer
            cursor: pointer
        &.select
            font-weight: 600
            color: var(--color-secondary)
            background: #FCE3E5
        .detailsItem
            div:first-child
                font-family: Roboto
                font-weight: bold
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
                color: #000
                width: 184px
                font-size: 14px
            div:last-child
                color: #000
                font-weight: 100
                font-size: 13px
                letter-spacing: 1px
                overflow-wrap: break-word
                overflow: hidden
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
        i
            text-align: right
