.custom-loader {
    // width: 50%;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--color-buttons);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 3s linear infinite; /* Safari */
    animation: spin 3s linear infinite;
    &__container {
        position: fixed;
        top: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(2px);
        z-index: 11;
    }
    &__simple {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

// .loader-container {
//     position: fixed;
//     top: 0px;
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     backdrop-filter: blur(2px);
//     z-index: 11;
//     .loader {
//         // width: 50%;
//         border: 16px solid #f3f3f3;
//         border-radius: 50%;
//         border-top: 16px solid var(--color-buttons);
//         width: 120px;
//         height: 120px;
//         // -webkit-animation: spin 2s linear infinite; /* Safari */
//         animation: spin 3s linear infinite;
//     }
// }

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
