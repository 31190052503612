.multipacha {
    &__button {
        border: none;
        color: #fff;
        background-color: var(--color-buttons);
        @extend %commonButton;
        &:hover {
            opacity: 0.8;
            transition: opacity 0.3s;
        }
        &-outline {
            @extend %commonButton;
            border: 1px solid var(--color-buttons);
            color: var(--color-buttons);
            background-color: #fff;
            &:hover {
                opacity: 0.5;
                transition: opacity 0.3s;
            }
        }
    }
}

%commonButton {
    padding: 0.5rem;
    border-radius: 10px;
    min-width: 100px;
    font-size: 0.875rem;
    min-height: 34.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
