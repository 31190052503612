.form_question
    min-height: 500px
    .container_options
        display: flex
        margin: 0 0 20px
        width: 100%
        justify-content: space-between
        flex-wrap: wrap
        text-align: left
        .select
            width: 30%
            display: flex
            flex-wrap: wrap
            &>select
                width: 100%
                height: 33px
                background: #FFFFFF
                border: 1px solid #D8CCCC
                box-sizing: border-box
                border-radius: 10px
                margin-top: 10px
                padding-left: 15px
                outline: none
        .input_question
            width: 100%
            height: 33px
            background: #FFFFFF
            border: 1px solid #D8CCCC
            box-sizing: border-box
            border-radius: 10px
            padding-left: 15px
            outline: none
        label
            width: 100%
            margin-bottom: 5px
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 14px
            color: var(--color-dark)
        .alternatives_content
            display: flex
            flex-wrap: wrap
            width: 100%
            h5
                margin: 0
            .alternatives_item
                width: 100%
                display: flex
                margin: 5px 0
                &>div
                    width: 40%
                    display: flex
                    margin-right: 20px
                    height: 33px
                    align-items: center
                    &>input
                        margin-right: 10px
                    &>button
                        background: var(--color-secondary)
                        border: none
                        width: 30px
                        height: 25px
                        color: #fff
                        border-radius: 5px
                p
                    margin: 0
            .alternatives_message
                margin: 5px 0
                width: 100%
            .alternatives_buttons
                margin: 5px 0
                width: 100%
                text-align: center
                button
                    color: #ffffff
                    cursor: pointer
                    height: 30px
                    padding: 0 20px
                    background: var(--color-secondary)
                    border: 1px solid #D8CCCC
                    box-sizing: border-box
                    border-radius: 10px
                    outline: none
                    margin: 0 50px
        &.button_options
            justify-content: center
            button
                color: #ffffff
                cursor: pointer
                height: 40px
                padding: 0 20px
                background: var(--color-secondary)
                border: 1px solid #D8CCCC
                box-sizing: border-box
                border-radius: 10px
                outline: none
                margin: 0 50px
    // .menssage_modal_form
.view_question
    &.border
        border: solid 2px var(--color-secondary)
        border-radius: 15px
        padding: 20px
        margin-bottom: 15px
        background: #fff
    .container_question
        display: flex
        margin: 0 0 20px
        width: 100%
        justify-content: space-between
        flex-wrap: wrap
        text-align: left
        .half
            width: 30%
            display: flex
            align-items: center
            label
                width: auto
        .input_question
            width: 100%
            height: 33px
            background: #FFFFFF
            border: 1px solid #D8CCCC
            box-sizing: border-box
            border-radius: 10px
            padding-left: 15px
            outline: none
        label
            width: 100%
            font-style: normal
            font-weight: normal
            font-size: 14px
            line-height: 14px
            color: var(--color-dark)
            &:first-child
                font-weight: bold
                color: var(--color-secondary )
            &:last-child
                margin-left: 10px
        .question
            word-break: break-all
            margin-top: 10px
        .question_view
            word-break: break-all
            border-radius: 15px
            border: solid 2px var(--color-secondary)
            margin-top: 10px
            padding: 5px
            width: 100%
        .alternatives_view
            text-align: start
            h3
                color: var(--color-secondary)
            ul
                li
                    list-style: none
                    color: var(--color-dark)
                    display: flex
                    align-items: center
                    i
                        margin-right: 15px
                        font-size: 20px
                        color: var(--color-secondary)
    .optSelectOption
        position: absolute
        right: 0px
        top: -100vh
        width: 180px
        transform: scaleY(0)
        transform-origin: center top
        color: #000
        width: 150px
        font-size: 14px
        z-index: 1
        align-items: flex-start
        background: #FFFFFF
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
        border-radius: 5px
        &.active
            top: 20px
            transition: transform .3s
            transform: scale(1)
            z-index: 3
        .onlyOpt
            color: #000000
            cursor: pointer
            width: 150px
            padding: 7px
            display: grid
            grid-template-columns: auto
            align-items: center
            grid-column-gap: 10px
            i
                font-size: 24px
            &:first-child
                margin-top: 8px
            &:last-child
                margin-bottom: 8px
            &:hover
                background: #B3B3B3
